<template>
    <div class="main">
        <div class="title">
            <span class="da">融视汇会员卡获取明细</span>
            <button class="f5" @click="f5"><i class="el-icon-refresh">{{ this.s }}</i></button>
        </div>
        <div class="find">
            <div class="find_box">
                <input class="input" @keydown.enter="find_fc" placeholder="请输入订单号" v-model="input_id">
                </input>
                <button class="button" @click="find_fc">立即查询</button>
            </div>
        </div>
        <div class="jilu" v-loading="loading">
            <el-table :data="tableData" style="width: 100%;height: 100%;"
                :header-cell-style="{ backgroundColor: '#393939', color: 'white' }"
                :row-style="{ backgroundColor: 'transparent', color: 'white', fontSize: '1.2vw' }" height="100%">
                <el-table-column prop="paymentKey" label="订单号" align="center">
                </el-table-column>
                <el-table-column prop="updatedAt" label="状态更新时间" width="200" align="center">
                </el-table-column>
                <el-table-column prop="status" label="订单状态" width="80" align="center">
                </el-table-column>
                <el-table-column prop="userName" label="操作" width="70" align="center">
                    <template slot-scope="scope">
                        <button @click="cheak(scope.$index)" class="jilu_button">查看</button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <transition name="fade">
            <div v-if="info_status" class="info">
                <div class="info_close" @click="info_close">
                    <i class="el-icon-close"></i>
                </div>
                <div class="info_title">
                    <span class="order_id">单号：{{ this.info.paymentKey }}</span>
                    <span class="order_id">类型：{{ this.info.cardType }}</span>
                    <span class="order_time">数量：{{ this.info.quantity }}</span>
                    <span class="order_time">支付方式：{{ this.info.paymentMethod }}</span>
                    <span class="order_time">状态更新时间：{{ this.info.updatedAt }}</span>
                </div>
                <div class="info_title2">
                    <span class="order_st">状态：{{ this.info.status }}</span>
                </div>
                <div class="info_remark">备注：{{ this.info.remark }}</div>
                <div class="info_key">
                    <li v-for="(item, index) in items" :key="index">{{ item }}</li>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
export default {
    name: 'findId',
    data() {
        return {
            input_id: null,
            loading: false,
            tableData: [
            ],
            info_status: false,
            info: {
            },
            items: {},
            i: null,

            s: null,
            timer: '',
            tim_flag: false,
        }
    },
    methods: {
        async f5() {
            if (this.tim_flag) {
                this.$message(this.s + "秒后重试")
            } else {
                for (this.i = 0; this.i < this.tableData.length; this.i++) {
                    try {
                        const response = await axios.get(global.url + "/buyMembershipCard?paymentKey=" + this.tableData[this.i].paymentKey);
                        if (response.data.code === 200) {
                            this.tableData[this.i] = response.data.data;
                            if (this.tableData[this.i].status == 'pending') {
                                this.tableData[this.i].status = '核审中';
                            } else if (this.tableData[this.i].status == 'approved') {
                                this.tableData[this.i].status = '通过';
                            } else if (this.tableData[this.i].status == 'rejected') {
                                this.tableData[this.i].status = '拒绝';
                            }
                        } else {
                            this.$message("失败" + response.data.msg);
                        }
                    } catch (error) {
                        this.loading = false;
                        this.$message("网络错误！请检查网络或联系客服"+error.msg);
                    }
                }
                this.$message("刷新成功")
                this.s = 60
                this.timer = setInterval(() => {
                    if(this.s > 0){
                        if(this.s == 60){
                            this.tableData = this.tableData
                        }
                        this.s--
                        this.tim_flag = true;
                    }else{
                        clearInterval(this.timer)
                        this.s=null
                        this.tim_flag = false;
                    }
                }, 1000);
            }
        },
        info_close() {
            this.info_status = false
        },
        cheak(orderIndex) {
            if (this.info_status) {
                this.info_status = false
            } else {
                this.info_status = true
                if (this.tableData[orderIndex].cardType == 'season_card') {
                    this.tableData[orderIndex].cardType = '季卡'
                } else if (this.tableData[orderIndex].cardType == 'month_card') {
                    this.tableData[orderIndex].cardType = '月卡'
                } else if (this.tableData[orderIndex].cardType == 'year_card') {
                    this.tableData[orderIndex].cardType = '年卡'
                }

                if (this.tableData[orderIndex].paymentMethod == 'alipay') {
                    this.tableData[orderIndex].paymentMethod = '支付宝'
                }else if (this.tableData[orderIndex].paymentMethod == 'usdt') {
                    this.tableData[orderIndex].paymentMethod = 'USDT'
                }else if (this.tableData[orderIndex].paymentMethod == 'wechat') {
                    this.tableData[orderIndex].paymentMethod = '微信'
                }
                this.info = this.tableData[orderIndex]
                this.items = this.tableData[orderIndex].cardList
            }
        },
        find_fc() {
            this.loading = true
            if (this.input_id == '') {
                this.loading = false
                this.$message("未填写订单号")
            } else {
                axios.get(global.url + "/buyMembershipCard?paymentKey=" + this.input_id)
                    .then(response => {
                        this.loading = false
                        let obj = this.tableData.find(item => item.paymentKey === this.input_id);
                        if (obj) {
                            this.$message("订单已查询，请在下方列表查看")
                        } else {
                            if (response.data.code == 200) {
                                if (response.data.msg == "操作成功") {
                                    this.tableData.unshift(response.data.data)
                                    if (this.tableData[0].status == 'pending') {
                                        this.tableData[0].status = '核审中'
                                    } else if (this.tableData[0].status == 'approved') {
                                        this.tableData[0].status = '通过'
                                    } else if (this.tableData[0].status == 'rejected') {
                                        this.tableData[0].status = '拒绝'
                                    }
                                    this.$message({
                                        type: 'success',
                                        message: '查询成功！'
                                    });
                                } else {
                                    this.$message(response.data.msg)
                                }
                            } else {
                                this.$message("订单不存在" + response.data.msg)
                            }
                        }
                    }).catch(error => {
                        this.loading = false;
                        this.$message("网络错误！请检查网络或联系客服"+error.msg);
                    })
            }
        }
    },

}
</script>

<style long="scss" scoped>
.main {
    position: relative;
    width: 100%;
    height: 100%;
}

.da {
    font-size: 2.4vw;
    font-family: ziti3;
}

.f5 {
    position: absolute;
    background-color: transparent;
    border: none;
    color: #53aaa7;
    font-size: 2vw;
    right: 2vw;
    top: 1.3vw;
}

.f5:hover {
    color: grey;
}

.title {
    margin-top: 1vw;
    margin-left: 9.5vw;
    color: white;
}



.find {
    position: relative;
    width: 35vw;
    height: 3vw;
    margin-top: 1vw;
    margin-left: 4vw;
    background-color: #e3e0dc;
    border-radius: 2vw;
}

.find_box {
    position: absolute;
    top: 0.4vw;
    left: 0.5vw;
    width: 34vw;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.input {
    width: 28vw;
    height: 2vw;
    border: none;
    border-radius: 2vw;
    font-size: 1vw;
}

.button {
    width: 6vw;
    height: 2vw;
    border: none;
    border-radius: 2vw;
    font-size: 1.2vw;
    font-family: ziti3;
    background-color: #53aaa7;
    color: white;
}

.button:hover {
    background-color: grey;
}

.jilu {
    margin-left: 4vw;
    margin-top: 1vw;
    width: 35vw;
    height: 35vw;
    font-family: ziti3;
}

.el-table {
    background-color: transparent;
    font-size: 1vw;
}

::v-deep .el-table .el-table__row:hover {
    color: black !important;
}

.jilu_button {
    background-color: #53aaa7;
    color: white;
    border-radius: 1vw;
    border: none;
    font-size: 1vw;
    font-family: ziti3;
}

.info {
    position: absolute;
    top: 0vw;
    left: 40vw;
    height: 48vw;
    width: 30vw;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(12px);
    border-radius: 1vw;
    border: 0.15vw solid #53aaa7;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.info_close {
    position: absolute;
    font-size: 1.5vw;
    right: 0.5vw;
    color: #53aaa7;
}

.info_title {
    font-size: 1.5vw;
    padding-left: 1vw;
    font-family: ziti3;
    background-color: transparent;
    color: white;
    border-radius: 0.5vw;
    margin-top: 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info_title2 {
    font-size: 2vw;
    font-family: ziti3;
    color: #53aaa7;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.info_remark {
    position: absolute;
    font-size: 1.3vw;
    font-family: ziti3;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    color: white;
    left: 1vw;
    width: 26vw;
    height: 5vw;
    border-radius: 2vw;
    border: 0.15vw solid #53aaa7;
    background-color: transparent;
}

.info_key {
    position: absolute;
    top: 17vw;
    left: 1vw;
    font-size: 1vw;
    width: 28vw;
    height: 27vw;
    text-align: center;
    color: white;
    background-color: transparent;
    margin-top: 1vw;
}


/* 定义过渡效果 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>