<template>
    <div class="window">
        <div class="main">
            <span class="kong">欢迎使用融视汇科技有限公司的产品及服务!</span>

            <span
                class="kong1">《融视汇服务协议》（以下亦称“本协议”）由您（以下亦称“用户”）和融视汇科技有限公司（以下亦称“我们”）缔结，对双方具有同等法律效力。请您仔细阅读本协议的全部内容，尤其是加粗展示的条款（包括约定我方责任、您享有的权利、争议解决方式及司法管辖等条款）与您的权益（可能）存在重大关系，请您留意重点阅读。若您认为加粗条款可能会导致您的部分或全部权利受损，请您务必再次仔细阅读，在确保您已经理解、接受了加粗条款的前提下，开始或继续使用融视汇科技有限公司的产品及服务（具体定义见第一节，以下亦称“我们的产品及服务”）。

                您使用我们的产品及服务（“使用”包括但不限于下载、安装、启动、浏览、注册、登录等行为中的一种或多种）即表示您已确认理解并同意本协议。如果您不接受本协议的任何条款，您应立即停止使用。

                融视汇平台上出现的相关操作提示、答疑指南、使用帮助或我们以其他方式向您展示的规则、流程等均构成我们的产品与服务相关规则的一部分，亦是本协议的一部分。</span>

            <span class="kong1">本《融视汇服务协议》将帮助您了解以下内容</span>
            <span class="kong2">1. 协议的定义及服务说明</span>
            <span class="kong2">2. 您的融视汇账号</span>
            <span class="kong2">3. 服务使用规则</span>
            <span class="kong2">4. 您的行为规范</span>
            <span class="kong2">5. 个人信息和未成年保护</span>
            <span class="kong2">6. 知识产权</span>
            <span class="kong2">7. 广告、第三方链接</span>
            <span class="kong2">8. 法律责任</span>
            <span class="kong2">9. 服务的变更和中断/终止</span>
            <span class="kong2">10. 通知和联系方式</span>
            <span class="kong2">11. 关于本协议</span>

            <span class="kong3">1. 协议定义及服务说明</span>
            <span class="kong1">1.1 本协议适用于融视汇科技有限公司的所有产品及服务，包括但不限于音视频、动漫、直播、广告、会员服务及其他由融视汇科技有限公司提供的产品及服务。
            </span>
            <span class="kong2">1.2 产品及服务使用范围：仅限中国境内（不包括香港、澳门及台湾地区）。如您超范围使用，可能受到限制或影响。
            </span>
            <span class="kong2">1.3
                融视汇平台（以下亦称“我们平台”或“平台”），包括但不限于iqiyi.com、pps.tv、ppstream.com、suike.cn、由融视汇科技有限公司直接所有或运营的任何网页、APP、小程序、公众号及其他客户端平台。
            </span>
            <span class="kong2">1.4 本协议所称“用户”，包括注册用户及未注册但使用我们的产品及服务的用户。</span>

            <span
                class="kong2">特别提醒：本协议为统一适用的一般性用户服务协议。由于我们的产品及服务较多，提供的内容也有所不同，因此针对特定产品及服务，我们还制定了单独的协议与规则，您应在充分阅读并同意后再使用。您在我们平台上使用第三方提供的产品及服务时，除遵守本协议外，还应遵守第三方的规则。
            </span>


            <span class="kong3">2. 您的融视汇账号</span>

            <span class="kong2">2.1 我们的大部分服务仅向注册用户提供，您在登录后方可使用，如您未注册或登录账号的，使用服务将受到一定限制。
            </span>
            <span class="kong2">2.2 根据法律法规及相关规定，部分服务或功能需要事先完成实名验证，您应按要求配合。如您未完成认证或认证失败将无法使用。
            </span>
            <span class="kong2"> 2.4 为保证您能顺利使用我们的产品及服务，您知悉并同意在使用融视汇账号时应遵守以下规则：
            </span>
            <span> 2.4.1
                您在注册融视汇账号时，应根据页面提示向我们提供完整、真实、准确、最新的必要注册信息。在提交注册信息后，如果上述信息发生变化，您应及时更改。您提交的昵称、头像和简介等注册信息中不得出现违法、侵权和不良信息，否则我们有权不通过审核。即使审核通过，如后续我们发现或经他人投诉且我们核实存在违法或侵权等情形的，我们有权无需通知您直接采取删除或重置账号信息、限期修改、收回已认证的昵称、限制账号功能等措施；
            </span>
            <span>2.4.2 除通过第三方账号授权登录外，您仅可通过我们或我们授权的渠道注册成为用户，不得通过包括但不限于购买、租用、借用、分享、利用、破坏我们的注册系统及其他未经我们许可的方式注册。
            </span>
            <span>2.4.3
                您享有融视汇账号的有限使用权，您的融视汇账号仅可由本人进行非商业使用，不得以转让、出租、出借、售卖、分享或其他任何方式交由他人使用。因您存在上述违规行为触发融视汇账号安全管理风控策略导致出现验证、账号退登、账号异常、被封停等风险，均由您自行承担，且融视汇有权根据您的违规情况决定是否中止或终止为您提供部分或全部服务。
            </span>
            <span class="kong2"> 2.5 您的账号管理及安全
            </span>
            <span>2.5.1
                您需对您享有使用权的融视汇账号下发生的所有行为及通过该账号所从事的所有行为承担责任。因此，您应自行负责并妥善、正确地保管、使用、维护您的融视汇账号和密码，并对您的账号和密码采取必要和有效的保密措施。非因我们法定过错导致您账号的任何遗失、泄露、被篡改、被盗以及其他因保管、使用、维护不当而造成的损失，您应自行承担；
            </span>
            <span> 2.5.2
                如您发现有人未经授权使用了您的账号，或您的账号存在其他异常情况无法正常登录使用，请按照我们的账号找回流程进行账号找回。在找回过程中，您需按要求提供相应信息及证明资料，请确保您所提供的内容真实有效，否则可能无法通过验证而找回失败；
            </span>
            <span>2.5.3
                为保护账号安全，防止账号被盗、恶意分享等情况发生，我们（可能）会不时或定期采用一种或多种方式对注册用户进行用户身份验证（如短信验证等）。如用户未成功通过验证，我们有合理理由怀疑该账号出现被盗等不安全情况，并可能视情节严重情况而单方决定是否中止或终止向该账号继续提供我们的产品及服务及采取进一步措施。您同意，我们采取前述措施是为了保护用户账号安全，我们无需因此承担任何责任。
            </span>
            <span class="kong2"> 2.6
                您知悉并同意，根据相关要求，我们会在您的账号个人信息页、发布信息页等位置展示您所使用账号的互联网协议（IP）地址归属地等信息，具体展示位置、形式、信息类型等相关安排以相关法律法规规定和实际展示为准。受限于技术措施，我们无法保证系统收集的您的联网协议（IP）地址的完全真实和准确，您亦不得擅自篡改相关IP地址，否则因此导致展示的信息不准确而引发的所有风险或法律责任均由您自行承担。
            </span>
            <span class="kong2"> 2.7
                基于以下情况，我们有权中止或永久终止您对账号的使用或收回您的融视汇账号，中断或终止向您继续提供产品及服务，且无需承担任何责任，由此给您带来的损失（包括但不限于会员权益过期，账号资料及相关数据清空、收益无法提现等）由您自行承担：
            </span>
            <span>2.7.1 根据您的主动申请；
            </span>
            <span>2.7.2 您违反法律法规或本协议的；
            </span>
            <span>2.7.3 根据我们必须遵守的法律法规、监管政策要求的；
            </span>
            <span> 2.7.4 您的使用对我们及他人的合法权益造成（或可能造成）侵害的；
            </span>
            <span> 2.7.5 您的账号连续180天未使用的。
            </span>
            <span class="kong2">2.8 账号注销 我们为您提供了融视汇账号注销功能，您可以通过在线注销、联系客服等方式注销账号。更多关于融视汇账号注销规则，请参阅《融视汇账号注销协议》。
            </span>


            <span class="kong3">3. 服务使用规则
            </span>
            <span class="kong2">3.1 基本原则
            </span>
            <span>3.1.1 您在使用我们的产品及服务时，应遵守相关法律法规及其他规范性文件，遵守公序良俗，不得从事任何违法违规或侵犯他人合法权益的活动；
            </span>
            <span> 3.1.2
                我们的产品及服务，均仅限于您在我们平台或我们明确授权的其他平台上进行使用。任何以破解、转译、转录等非法或未经我们明确允许的手段将我们的产品及服务与我们平台或其他我们授权的平台相分离的行为，均不属于本协议中约定的使用我们的产品及服务的方式。我们保留追究您的法律责任及索赔的权利；
            </span>
            <span> 3.1.3 我们授予您对我们的产品及服务一项个人的、非独家的、不可转让的、非商业用途的、可撤销的、有期限的使用许可，即您仅可本人非商业地使用我们的产品及服务；
            </span>
            <span>3.1.4
                您在使用我们的产品及服务时可能需要下载我们的软件，您仅可为非商业且使用本服务之目的下载和使用。为了提升用户体验、完善服务内容，我们会不时对软件进行更新。新版本发布后，旧版本可能无法使用或部分功能受限，建议您将软件更新到最新版本。
            </span>
            <span class="kong2"> 3.2
                您知悉并同意，受限于宽带和网络连接问题，您在使用我们的产品及服务的过程中可能会出现需较长时间缓冲或卡顿或其他类似情况，此类情况并非我们的过错，我们无需向用户承担责任。同时，为了改善用户体验、减少缓冲或卡顿的情况，我们可以采取一系列视频加速通用技术和未来的创新技术（包括但不限于我们自研的OSS技术等），前述技术的使用可能会产生一些额外的宽带、设备系统占用。如您对此有任何疑问，可联系我们以获得技术支持。
            </span>
            <span class="kong2">3.3
                我们提供的产品及服务需接入互联网方可使用，因此您需自行承担使用产品及服务过程中所产生的上行和下行的网络通信及流量费用，上述费用将由网络运营商收取。您亦可通过产品或终端的相关设置页面对网络使用信息进行相关设置。
            </span>
            <span class="kong2"> 3.4 您可以向我们提出咨询和获得使用我们的产品及服务的合理技术支持，但不应超过我们的合理承受限度。</span>

            <span class="kong2"> 3.5
                您知悉并同意，我们平台上的影视综艺等内容由我们根据自身运营策略等多方面因素综合考量后确定（包括但不限于免费和付费内容的排播时间与上下线、付费方案、转会员免费或非会员免费的时间、内容观看地域等），我们可能会根据法律法规及监管要求、版权状态变化、自身运营策略、用户需求、舆情问题、内容质量等作出调整，包括但不限于调整播出进度、停更或下线部分视频内容等。若内容下线，即使您已经将该等内容下载或缓存至本地，您仍可能无法观看；若您的会员或额外付费内容到期，您也无法继续观看（包括已经下载或缓存至本地的情形）。
            </span>
            <span class="kong2">3.6 虚拟资产的使用规则：
            </span>
            <span>3.6.1 在遵守一定使用规则前提下，您在我们平台通过免费或付费方式获得的产品及服务均属于虚拟资产；
            </span>
            <span>3.6.2 虚拟资产不可兑换现金或转让、买卖、置换、抵押等，已消耗的虚拟资产不可修改、退还；
            </span>
            <span>3.6.3 虚拟资产的定价、获得途径、使用方法、有效期限等以我们平台公示为准。
            </span>
            <span class="kong2"> 3.7 付费服务使用规则：
            </span>
            <span>3.7.1 我们平台提供的服务众多，部分为付费服务，如VIP会员、商城、数字藏品、剧集/影片付费点播等。您可以付费购买后使用，付费服务的具体开通、使用规则以相关页面说明为准。
            </span>
            <span>3.7.2 我们售卖的VIP会员服务（如融视汇VIP会员、融视汇黄金会员、融视汇铂金会员等）为用户提供了丰富的会员权益，具体以平台上的页面说明为准。
            </span>
            <span>3.7.3
                VIP会员服务仅限于申请账号自行使用。VIP会员服务系网络服务和虚拟服务，采用先收费后服务的方式，会员费用是您所购买的会员服务所对应的网络服务和虚拟服务价格，而非预付款或者存款、定金、储蓄卡等性质，VIP会员服务一经开通后不可转让或退款（如因VIP会员服务存在重大瑕疵导致您完全无法使用、本协议另有约定、法律法规要求必须退款的或经我们判断后认为可以退款等除外）。
            </span>
            <span>3.7.4
                您在开通、使用融视汇VIP会员服务的过程中应遵守《融视汇VIP会员服务协议》及会员权益相关页面公示的规则。如您通过第三方平台开通融视汇VIP会员的，请您在我们平台仔细阅读并确认同意会员服务协议及会员权益规则内容后再使用。您在使用其他付费服务过程中亦应遵守对应的协议和规则，否则您无法享受相应的服务。
            </span>
            <span>3.7.5 您理解并同意，我们有权根据自身运营策略需要，以免费或付费等方式将我们的服务或功能提供给不同类型的用户使
                用。我们保留就免费服务或功能进行收费的权利，亦有权通过直接免费、限时免费或附条件免费等方式将部分或全部付费服务或功能提供给免费用户体验和使用。
            </span>


            <span class="kong3"> 4. 您的行为规范</span>

            <span class="kong2"> 4.1
                您应在遵守法律法规及其他规范性文件与本协议的前提下访问和使用我们的产品及服务。您同意，您不会进行或利用我们的产品及服务进行下列活动（也不为他人进行下列活动提供便利和支持）：
            </span>
            <span>4.1.1 从事违法犯罪活动；
            </span>
            <span>4.1.2 从事侵犯或可能危害我们或任何第三方合法权益的活动；
            </span>
            <span>4.1.3
                采取技术手段（包括但不限于病毒、爬虫软件等任何自动化程序、脚本、软件）及其他不正当手段攻击、破坏我们的软件及产品和服务的安全措施、对我们的软件及产品和服务进行反向工程、反编译、反汇编或其他类似行为、提取、复制、篡
                改、对外发布或出售我们的源代码或其他信息；
            </span>
            <span> 4.1.4
                通过不正当手段或违反诚实信用原则的方式（如利用规则漏洞、系统漏洞、汇率差、盗刷信用卡、恶意退费等黑产投机行为或违背我们提供产品及服务的初衷的方式）获取、使用我们的产品或服务、破坏我们的正常运营；
            </span>
            <span> 4.1.5 未经我们授权，以视频采集、直播、录屏、截屏、下载后分享、转发等任何方式，将我们的产品及服务、我们平台的文字、图片、音频、视频等所有内容提供给任何第三方或在第三方平台进行传播；
            </span>
            <span>4.1.6 伪造标题、发布者信息或以其他方式操控识别资料，使人误认为该内容为我们所发布、传送；
            </span>
            <span>4.1.7 窃取、盗用他人的账号及账号下财产或利用他人账号从事违法犯罪、侵权行为等；
            </span>
            <span> 4.1.8 其他未经我们明示授权许可或违反本协议、法律法规或监管政策的行为。
            </span>
            <span class="kong2">4.2 您利用我们的产品及服务制作、上传、发布的包括但不限于文字、图片、音频、视频等所有内容不得包括以下内容：
            </span>
            <span> 4.2.1 反对宪法和法律的基本原则的内容；
            </span>
            <span> 4.2.2 危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的内容；
            </span>
            <span> 4.2.3 危害网络安全，损害国家荣誉和利益的内容；
            </span>
            <span>4.2.4 破坏民族团结、煽动民族仇恨、民族歧视、破坏民族团结的内容；</span>

            <span>4.2.5 破坏国家宗教政策、宣扬邪教和封建迷信的内容；
            </span>
            <span> 4.2.6 散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的内容；
            </span>
            <span> 4.2.7 侮辱、诽谤、歪曲、丑化、亵渎、否定英雄烈士及其事迹和精神的内容；
            </span>
            <span>4.2.8 侮辱或者诽谤他人，或以其他方式侵犯他人名誉、隐私或其他合法权益的内容；
            </span>
            <span>4.2.9 有危害未成年人身心健康或其他令人反感的内容；
            </span>
            <span>4.2.10 广告或商业推广信息的内容，包括但不限于广告信息的标题、简介、二维码、链接（但我们另行书面许可的除外）；
            </span>
            <span>4.2.11 其他法律法规、监管政策禁止的内容。
            </span>
            <span class="kong2">4.3
                您在发布或传播利用基于深度学习、虚拟现实等的新技术新应用制作的非真实音视频信息时，应当以显著方式予以标识。您发布或传播含有可能引发或者诱导未成年人模仿不安全行为、实施违反社会公德行为、产生极端情绪、养成不良嗜好等可能影响未成年人身心健康的信息的，应当在信息发布前予以显著提示。
            </span>
            <span class="kong2">4.4
                您通过我们平台所上传、发布、传播的任何内容（包括使用AI生成内容，如有）并不反映我们的观点或政策，您对此独立完整的承担法律责任。我们有权进行审核，但您不因我们的审核而免除任何法律责任，我们亦不因审核而对您上传、发布的内容承担法律规定以外的任何责任。同时，您应对平台其他用户提供的内容自行加以判断，并承担因使用该内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
            </span>
            <span class="kong2">4.5 您知悉并同意，除法律法规另有规定外，我们对您上传、发布、分享的内容无储存义务，您应自行备份。如法律法规要
                求，我们将监控您在平台上传的内容，且我们的后台记录有可能作为您违反法律法规、违约、侵权的证据。
            </span>


            <span class="kong3">5. 个人信息和未成年人保护
            </span>
            <span class="kong2">5.1
                我们深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，亦将保护您的个人信息作为高度审慎的义务。关于个人信息处理和保护规则等内容，请您查阅我们的产品隐私政策的全文（如手机端融视汇APP的查询路径为：我的-设置-隐私政策及摘要）。
            </span>
            <span class="kong2">5.2 我们非常注重未成年人的保护
            </span>
            <span>5.2.1 若您为未成年人，应在监护人监护、指导下阅读本协议，并且使用我们的产品及服务需获得您监护人的同意。
            </span>
            <span>5.2.2
                未成年人的监护人应履行监护职责，指导并监督未成年人上网安全问题，防范于未然。我们不鼓励未成年人在使用我们的产品及服务时进行消费，如未成年人有消费需求的，应由监护人操作或在监护人明示同意下操作。同时，我们提醒您注意，作为监护人，您应保管好您的支付设备、账号及密码，以避免未成年人在未取得您同意的情况下通过您的融视汇账号消费。
            </span>
            <span>5.2.3 我们的产品及服务中专门为未成年人提供了青少年模式，青少年模式下我们按照国家有关规定和标准对使用时段、时
                长、消费功能等方面进行了限制，并以便捷的方式为监护人履行监护职责提供时间管理、权限管理以及消费管理等功能。如您为未成年人的监护人，我们建议您应为被监护人使用我们的产品及服务时选择青少年模式，以便更好地预防和干预未成年人沉迷网络。
            </span>
            <span>5.2.4 除非您能提供未成年人是未经您同意后进行消费的证据并经过我们的核实确认，否则我们将有充分、合理理由认为未成年人使用我们的产品及服务并消费已经获得了您的同意。
            </span>
            <span>5.2.5 我们提醒未成年人在使用我们的产品与服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。
            </span>


            <span class="kong3">6. 知识产权</span>

            <span class="kong2"> 6.1
                我们在产品和服务中提供的所有内容（包括但不限于平台名称、logo、软件、软件名称与商业标识、产品界面、文字、图片、音频、视频等）的知识产权全部归我们所有或我们享有相关合法权利。未经我们事先书面明确同意，您不得以任何形式使用前述内容、处理或创造相关衍生作品，但如您是作品的完整权利人、您已获得相关权利人授权或融视汇与您另有约定的除
                外。</span>

            <span class="kong2">6.2 我们所有和享有的知识产权和其他相关合法权利，不因您的任何使用行为而发生权利转移。</span>

            <span class="kong2"> 6.3
                您在我们平台上传、发布或传输文字、图片、音频、视频等内容即视为您是前述内容的著作权人或合法授权人。除双方另有约定外，您在使用平台时将前述内容公开发布、传播、分享的行为代表了您有权且同意在全世界范围内、永久性的、不可撤销的、免费的授予我们可对该等内容行使除专属于著作权人的人身权利以外的全部著作权权利（包括但不限于复制、发行、出租、展览、表演、放映、传播、摄制、改编、翻译、汇编您的内容，与上述权利相关的商业开发、宣传和推广等服务的权利，含转授权及维权权利）。
            </span>
            <span class="kong2"> 6.4
                除法律法规另有规定或双方另有约定外，您在使用融视汇提供的大数据模型相关应用时，基于您有权上传的内容进而生成的内容知识产权归您自己享有，但如您上传的内容涉及其他第三方权利人，您在使用生成结果前应自行获得该权利人的合法授权。您亦需自行承担上传和生成内容的风险和责任，融视汇不因提供大数据模型相关应用的技术服务而对您上传和生成的内容向您或第三方承担任何责任。
            </span>


            <span class="kong3"> 7. 广告、第三方链接</span>

            <span class="kong2">7.1
                您知悉并同意，我们在提供产品及服务的过程中，在符合法律法规的前提下，保留以各种方式投放商业性广告（包括广告链接）或其他任何类型的商业信息的权利（包括但不限于在我们平台的任何位置上投放广告以及在您上传、传播的内容中投放广告）。您进一步同意，为更好地向您提供服务，您在使用我们的产品及服务过程中会（可能）收到我们通过弹窗形式（如首页开屏弹窗等）向您推送的相关信息或广告推送服务，上述推送服务的产品功能由北京融视汇科技有限公司或其关联公司提
                供，实际推送服务呈现的位置、频率等依据不同的产品/服务略有不同。但请您放心，我们将遵守《互联网弹窗信息推送服务管理规定》及相关法律法规，科学合理地规划弹窗信息推送的内容、方式以及弹窗频率，避免对您造成过度打扰。
            </span>
            <span class="kong2">7.2 在符合法律法规的前提下，我们有权自主决定在我们的产品与服务中的广告投放的形式、时长、位置、内容而不受任何干预。
            </span>
            <span class="kong2"> 7.3
                您知悉并同意，我们平台中的信息并非全部由融视汇发布，对于第三方发布的内容及广告主投放的广告，我们会尽合理的审查义务，您亦应该审慎判断其真实性、可靠性。除法律明确规定由广告发布者承担的责任外，我们不承担任何额外责任，但根据需要会依法提供必要的协助。

            </span>

            <span class="kong3">8. 法律责任</span>

            <span class="kong2">8.1 您知悉并同意，除法律法规另有规定或本协议另有约定外，我们的产品及服务仅按“现状”（
                为免歧义，“现状”指我们的产品及服务的实时现状，而非您与我们签署本协议时的现状）以及与您之间的书面约定提供，我们不对提供的产品及服务（含技术和信息）作出任何明示、暗示、永久性的承诺或保证，包括但不限于质量、稳定、正确、及时、完整、连贯、安全以及之前提供的产品功能、营销活动、优惠福利、影视内容等一直持续提供。为适应相关法律规定、提高运营效果或调整用户体验、符合版权要求，适应技术革新等目的，我们会（可能）对产品功能、营销活动、付费服务及规则、优惠福利、影视内容等进行变更。
            </span class="kong2">
            <span class="kong2"> 8.2 除法律法规另有规定外，我们仅对因我们的直接过错导致我们提供的产品及服务对您造成的直接经济损失承担法律规定范围内的赔偿责任。
            </span>
            <span class="kong2">8.3 如您有违反本协议约定的行为的，我们有权采取以下一种或多种措施：
            </span>
            <span>8.3.1 直接删除、屏蔽、下架您发布的内容，而无需事先通知；
            </span>
            <span> 8.3.2 视情节严重程度限制、封禁您的账号，扣除、清零部分或全部免费及付费权益与虚拟资产，暂停、永久禁止您和相关第三方（如有）使用我们的产品及服务；
            </span>
            <span> 8.3.3 追究您的违约或侵权责任。
            </span>
            <span class="kong2"> 8.4 如出现以下情况导致我们或使用我们产品及服务的第三方遭受诉讼或其他纠纷的，您同意为我们及第三方提供抗辩，并赔偿我们及第三方遭受的损失：
            </span>
            <span>8.4.1 您违反法律规定或本协议的任何条款的；
            </span>
            <span>8.4.2 您侵犯或可能侵犯任何第三方的知识产权或其他合法权益的。
            </span>
            <span> 您应当尽力与我们合作对相关索赔进行抗辩，同时我们保留自费进行独自抗辩并向您索赔的权利。
            </span>


            <span class="kong3"> 9. 服务的变更和中断/终止</span>

            <span class="kong2">9.1
                在法律法规允许的最大限度内，我们有权根据我们的业务发展情况，暂时或永久地变更或终止我们的产品及服务（或任何一部分），我们就该等变更或终止对您和任何第三人不承担违约责任。如因此导致您实质性的主要权益遭受损失的，在您提出申请后，我们将根据我们核实确认的您的直接经济损失适当补偿。
            </span>
            <span class="kong2">9.2 除9.1条所述的情形外，在发生如下情况时，我们有权中断或终止向您提供产品及服务：
            </span>
            <span>9.2.1 您主动提出要求的；
            </span>
            <span>9.2.2 您存在任何违反国家法律法规或监管政策、本协议或其他您与我们之间的约定、融视汇平台规则或有损害我们的声誉、权益的行为的；
            </span>
            <span>9.2.3 根据监管部门的要求；
            </span>
            <span> 9.2.4 为维护账号与系统安全等紧急情况之需要；
            </span>
            <span>9.2.5 融视汇不可控因素（是指融视汇不能预见、不能避免且不能克服的客观情况，包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）。
            </span>
            <span class="kong2">9.3 发生9.2条所述情形时，除法律法规另有规定外，我们无需向您和第三人承担任何责任。
            </span>


            <span class="kong3">10. 通知和联系方式
            </span>
            <span class="kong2">10.1 本协议项下我们对用户所有的通知均可通过网页公告、站内信、弹窗信息、电子邮件、手机短信或常规的信件传送等任一方式进行，该等通知于发送之日视为已送达您。
            </span>
            <span class="kong2">10.2 如您对本协议或使用我们的产品与服务相关的事宜有任何问题，可通过以下方式联系我们：
            </span>
            <span>在线通道：您可与我们平台上产品功能页面的在线客服联系或者在线提交意见反馈；
            </span>
            <span>人工客服通道：您可以拨打我们的客服电话与我们联系；
            </span>
            <span>其他方式：我们提供的其他反馈通道。
            </span>
            <span>我们会在收到您的反馈后尽快答复。
            </span>


            <span class="kong3">11. 关于本协议
            </span>
            <span class="kong2">11.1
                我们可能会随时更新本协议的内容，该等更新构成本协议的一部分。我们将在本协议生效前通过发布通知、弹窗、站内信或其他任一方式通知您，在您继续使用我们的产品及服务前，请仔细阅读本协议的更新版本。若您不同意变更后的内容，可以选择停止使用我们的产品服务；若您继续使用，即表示您充分阅读、理解并同意接受经修订的《融视汇服务协议》的约束。更新后的协议于协议首页约定生效之日起生效。
            </span>
            <span class="kong2">11.2
                本协议的解释及争议解决均适用中国境内（为本协议之目的，不包含香港、澳门及台湾地区）法律（不包括冲突法）。如就本协议的签订、履行等发生争议的，双方应友好协商解决；协商不成时，任何一方均可向被告住所地有管辖权的人民法院提起诉讼。
            </span>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'privacy',
    }
</script>
    
<style lang="scss" scoped>
.window{
    position: relative;
    color: white;
    font-family: ziti3;
    width: 80%;
    height: 80%;
    margin-top: 5%;
    margin-left: 10%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 2vw;

}

.main {
    position: absolute;
    text-align: start;
    color: white;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background-color: transparent;

    flex: 1;
    overflow: auto;
    box-sizing: border-box;

    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.kong {
    font-size: 2vw;
}

.kong1 {
    margin-top: 5%;
    font-size: 1.2vw;

}

.kong2 {
    margin-top: 1%;
}

.kong3 {
    margin-top: 20%;
}
</style>