<template>
    <div class="vw-table-wrapper">
      <table class="vw-table">
        <thead>
          <tr>
            <th v-for="(col, index) in columns" :key="index" :style="{ width: col.width }">{{ col.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td v-for="(col, colIndex) in columns" :key="colIndex" :style="{ width: col.width }">{{ row[col.prop] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      columns: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
    },
    computed: {
      // 动态计算每列宽度（基于vw单位）
      computedColumns() {
        return this.columns.map(col => ({
          ...col,
          width: col.width || `${(100 / this.columns.length)}vw`, // 默认宽度为1/n vw
        }));
      }
    }
  };
  </script>
  
  <style scoped>
  .vw-table-wrapper {
    overflow-x: auto;
  }
  
  .vw-table {
    width: 100%;
    border-collapse: collapse;
    /* table-layout: fixed; 让每列宽度按指定的固定宽度分配 */
  }
  
  .vw-table th,
  .vw-table td {
    border-bottom: none;
    padding: 0.5vw;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  </style>