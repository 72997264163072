import Vue from 'vue'
import VueRouter from 'vue-router'
import download from '@/views/xiazaizhongxin/download.vue'
import shouye from '@/views/shouye/shouye.vue'
import index from '@/views/index.vue'
import Myself from '@/views/gerenzhongxin/myself.vue'
import GerenShouye from '@/components/gerenzhongxin/gerenShouye.vue'
import ShenqingTixian from '@/components/gerenzhongxin/shenqingTixian.vue'
import WodeTuandui from '@/components/gerenzhongxin/wodeTuandui.vue'
import RenwuJilu from '@/components/gerenzhongxin/renwuJilu.vue'
import AnquanZhongxin from '@/components/gerenzhongxin/anquanZhongxin.vue'
import YonghuXiyi from '@/components/gerenzhongxin/yonghuXiyi.vue'
import Xieyi from '@/components/gerenzhongxin/yonghuxieyi/xieyi.vue'
import Getvip from '@/views/huiyuankahuoqu/getvip.vue'
import Gouka from '@/components/huoquhuiyuanka/gouka.vue'
import FindId from '@/components/huoquhuiyuanka/findId.vue'
import Login from '@/views/dengluzhuce/login.vue'
import Register from '@/components/login/register.vue'
import Gologin from '@/components/login/gologin.vue'
import Changekey from '@/components/gerenzhongxin/anquanzhongxin/changekey.vue'
import Changejy from '@/components/gerenzhongxin/anquanzhongxin/changejy.vue'
import Jihuo from '@/components/gerenzhongxin/jihuo/jihuo.vue'
import YongjinJilu from '@/components/gerenzhongxin/yongjinJilu.vue'
import Privacy from '@/views/yinsixieyi/Privacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: '',
        name: 'shouye',
        component: shouye
      },{
        path: 'privacy',
        name: 'privacy',
        component: Privacy
      }, {
        path: 'download',
        name: 'download',
        component: download
      }, {
        path: 'myself',
        name: 'myself',
        component: Myself,
        children: [
          {
            path: '',
            redirect: '/myself/gerenshouye'
          },
          {
            path: 'gerenshouye',
            name: 'GerenShouye',
            component: GerenShouye
          }, {
            path: 'ShenqingTixian',
            name: 'ShenqingTixian',
            component: ShenqingTixian
          }, {
            path: 'WodeTuandui',
            name: 'WodeTuandui',
            component: WodeTuandui
          }, {
            path: 'RenwuJilu',
            name: 'RenwuJilu',
            component: RenwuJilu
          },
          {
            path: 'ShenqingTixian',
            name: 'ShenqingTixian',
            component: ShenqingTixian
          }, {
            path: 'AnquanZhongxin',
            name: 'AnqunaZhongxin',
            component: AnquanZhongxin
          }, {
            path: 'YongjinJilu',
            name: 'YongjinJilu',
            component: YongjinJilu
          }, {
            path: 'YonghuXieyi',
            name: 'YonghuXieyi',
            component: YonghuXiyi,
            children: [
              {
                path: '',
                name: 'Xieyi',
                component: Xieyi
              }
            ]
          }, {
            path: 'changekey',
            name: 'changekey',
            component: Changekey
          }, {
            path: 'changejy',
            name: 'changejy',
            component: Changejy
          }, {
            path: 'jihuo',
            name: 'jihuo',
            component: Jihuo
          }
        ]
      }, {
        path: 'getvip',
        name: 'getvip',
        component: Getvip,
        children: [
          {
            path: '',
            name: 'Gouka',
            component: Gouka,
          }, {
            path: 'findId',
            name: 'findId',
            component: FindId
          },
        ]
      }, {
        path: 'login',
        name: 'login',
        component: Login,
        children: [
          {
            path: '',
            name: 'gologin',
            component: Gologin
          }, {
            path: 'register',
            name: 'register',
            component: Register
          }
        ]
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
