<template>
    <div class="main">
        <transition name="fade">
            <div v-if="ps" class="ps" v-loading="loading2">
                <div v-if="ps_1" class="ps1">
                    <span class="ps_title">请确认提现信息</span>
                    <div class="ps_info">
                        <span>账号：{{ this.name }}</span>
                        <span>提现余额：{{ this.withdraw.requestAmount }}元</span>
                        <span>提现方式：{{ this.selectedValue }}</span>
                        <span>提现账号：{{ this.pay_id }}</span>
                        <span>{{ xm_lx }}：{{ this.pay_name }}</span>
                    </div>
                    <button class="ps_bt_cancle" @click="ps1_cancle">取消</button>
                    <button class="ps_bt_ok" @click="ps1_ok">确定</button>
                </div>
                <div v-if="ps_2" class="ps2">
                    <span class="ps_title">请输入交易密码</span>
                    <input class="ps_input" @keydown="preventSpace1" type='password' @input="countInputLength"
                        v-model="withdraw.transactionPassword" placeholder="交易密码" />
                    <span class="ps_ps">{{ ps_remark }}</span>
                    <button class="ps_bt_cancle" @click="ps1_cancle">取消</button>
                    <button class="ps_bt_ok" @click="ps2_ok">确定</button>
                </div>
            </div>
        </transition>
        <div class="ketixian" v-loading="loading1">
            <div class=ketixian_zz>
                <span class="ktx_wz">当 前 账 户 余 额</span>
                <div class="ktx_num">
                    <img class="jinbi" src="../../assets/gerenzhongxin/shengqingtixian/jinbi.png" />
                    <span class="jinbi_num">{{ ktx }} 元</span>
                </div>
            </div>
        </div>
        <div class="tixian" v-loading="loading3">
            <div class="fangshi">
                <span class="da">提现方式</span>
                <div class="zz">
                    <label v-for="option in options" :key="option" :class="['custom-radio', {
                        'selected-radio': selectedValue === option.name,
                        'disabled-radio': disabledOptions.includes(option.name)
                    }]">
                        <input type="radio" :value="option.name" v-model="selectedValue" class="radio-input"
                            @change="handleSelection(option.name)">
                        <img class="icon" :src="option.url" />
                        {{ option.name }}
                    </label>
                </div>
                <!-- <div class="fs_wz">
                    <span class="xiao_zhifubao">已开通</span>
                    <span class="xiao_weixin">维护中</span>
                    <span class="xiao_USDP">维护中</span>
                </div> -->
            </div>
            <div class="num">
                <span class="da">提现金额</span>
                <el-row class="zz_1">
                    <label v-for="option in options_num" :key="option" :class="['custom-radio_num', {
                        'selected-radio_num': selectedValue_num === option,
                        'disabled-radio': disabledOptions_num.includes(option)
                    }]">
                        <input type="radio" :value="option" v-model="selectedValue_num" class="radio-input_num"
                            @change="handleSelection_num(option)">
                        <div class="num_wz">
                            <span class="num_da">{{ option }}元</span>
                            <span class="num_xiao">￥{{ option }}</span>
                        </div>
                    </label>
                </el-row>
                <div class="tixian_button_zz">
                    <button class="tixian_button" @click="go_withdraw">申请提现</button>
                </div>
            </div>
            <div class="shuoming">
                <span class="da">提现说明</span>
                <span class="xiao">1.1元提现限1次</span>
                <span class="xiao">2.提现手续费每次6元</span>
                <span class="xiao">3.10元或50元首次提现免费(天卡用户不免手续费)</span>
            </div>
        </div>
        <div class="jilu" v-loading="loading4">
            <div class="jl_wz">
                <spam class="da">提现记录</spam>
            </div>
            <div class="list">
                <el-table :data="tableData" style="width: 100%;height: 100%;"
                    :header-cell-style="{ backgroundColor: '#393939', color: 'white' }"
                    :row-style="{ backgroundColor: 'transparent', color: 'white' }" height="100%">
                    <el-table-column prop="requestedAt" label="提交时间" width="200" align="center">
                    </el-table-column>
                    <el-table-column prop="status" label="提现状态" align="center">
                    </el-table-column>
                    <el-table-column prop="requestAmount" label="提现金额(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="actualAmount" label="到账金额(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="fee" label="手续费(元)" align="center">
                    </el-table-column>
                    <el-table-column label="备注" align="center">
                        <template slot-scope="scope">
                            <div @mouseover="cheak_over(scope.$index)" @mouseleave="cheak_leave">
                                <i class="el-icon-more"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <transition name="fade">
                <div v-if="tot_flag" class="tot">
                    <span class="tot_title">状态：{{ tots.status }}</span>
                    <span class="tot_fee">方式：{{ tots.paymentMethod }}</span>
                    <span class="tot_actualAmount">账号：{{ tots.paymentAccount }}</span>
                    <span class="tot_cheak">核审时间</span>
                    <span class="tot_cheaktime">{{ tots.processedAt }}</span>
                    <span class="tot_remark">备注</span>
                    <span class="tot_context">{{ tots.reviewRemark }}</span>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
import Pagination from './zujian/Pagination.vue';

export default {
    name: 'ShengqingTixian',
    components: {
        Pagination
    },
    data() {
        return {
            xm_lx: '提现姓名',
            input_length: 0,
            tot_flag: false,
            ktx: null,
            radio1: '1',
            radio2: '1',
            selectedValue: '支付宝',
            options: [
                {
                    name: '支付宝',
                    url: 'https://rongshihui.cdn2.cc/files/assets/gerenzhongxin/shengqingtixian/zfb.png'
                }, {
                    name: 'USDT',
                    url: 'https://rongshihui.cdn2.cc/files/assets/gerenzhongxin/shengqingtixian/USDT.png'
                },
                {
                    name: '微信',
                    url: 'https://rongshihui.cdn2.cc/files/assets/gerenzhongxin/shengqingtixian/wx.png'
                }

            ],
            disabledOptions: ['微信'],
            selectedValue_num: null,
            options_num: [1, 10, 50, 100, 200],
            disabledOptions_num: [],

            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            withdraw: {
                paymentMethod: null,
                requestAmount: null,
                transactionPassword: null,
            },
            ps_1: false,
            ps_2: false,
            ps: false,
            i: null,

            name: null,
            pay_name: null,
            pay_id: null,

            tableData: [],

            tots: {},
            timer: '',

        }
    },
    methods: {
        preventSpace1(event) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                return; // 不做任何操作，允许删除
            }
            // 如果按下的是空格键（keyCode 32 或 event.key === ' '），则阻止事件
            if (event.key === ' ' || event.keyCode === 32) {
                event.preventDefault();
            }

            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();  // 阻止非数字字符输入
            }
        },
        cheak_over(index) {
            this.tots = this.tableData[index]
            if (this.tots.paymentMethod == 'alipay') {
                this.tots.paymentMethod = '支付宝'
            } else if (this.tots.paymentMethod == 'usdt') {
                this.tots.paymentMethod = 'USDT'
            }
            this.tot_flag = true
        },
        cheak_leave() {
            this.tot_flag = false
        },
        go_withdraw() {
            if (this.selectedValue == null) {
                this.$message("未选择支付方式!")
            }
            if (this.selectedValue_num == null) {
                this.$message("未选择提现金额")
            }
            if (this.ktx < this.selectedValue_num) {
                this.$message("余额不足")
            }
            if ((this.selectedValue != null) && (this.selectedValue_num != null) && (this.ktx > this.selectedValue_num)) {
                if (this.selectedValue == '支付宝') {
                    this.withdraw.paymentMethod = 'alipay'
                } else if (this.selectedValue == 'USDT') {
                    this.withdraw.paymentMethod = 'usdt'
                }
                this.withdraw.requestAmount = this.selectedValue_num
                this.name = Cookies.get("name")

                this.loading3 = true
                axios.get(global.url + '/userPaymentInfo', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                })
                    .then(response => {
                        if (response.data.code == '200') {
                            this.loading3 = false
                            if (this.withdraw.paymentMethod == 'alipay') {
                                if ((response.data.data.alipayName != null) && (response.data.data.alipayAccount != null)) {
                                    this.pay_name = response.data.data.alipayName
                                    this.pay_id = response.data.data.alipayAccount
                                    this.ps = true
                                    this.ps_1 = true
                                    this.xm_lx = '提现姓名'
                                } else {
                                    this.$message("未绑定支付宝信息，请前往安全中心绑定")
                                }
                            } else if (this.withdraw.paymentMethod == 'usdt') {
                                if ((response.data.data.usdtChainType != null) && (response.data.data.usdtWalletAddress != null)) {
                                    this.pay_name = response.data.data.usdtChainType
                                    this.pay_id = response.data.data.usdtWalletAddress
                                    this.ps = true
                                    this.ps_1 = true
                                    this.xm_lx = '链接类型'
                                } else {
                                    this.$message("未绑定USDT信息，请前往安全中心绑定")
                                }
                            }

                        } else if (response.data.code == '400') {
                            this.loading3 = false
                            this.$message("未绑定任何提现信息，请前往安全中心绑定")
                        } else {
                            this.loading3 = false
                            this.$message('错误!' + response.msg);
                        }
                    })
                    .catch(error => {
                        this.loading3 = false
                        this.$message('失败!' + error.msg);
                    });
            }
        },
        ps1_ok() {
            this.ps_1 = false
            this.ps_2 = true
        },
        ps1_cancle() {

            this.$message("用户取消提现申请")
            this.ps = false
            this.ps_2 = false
            this.withdraw.transactionPassword = null
        },
        countInputLength() {
            this.input_length = this.withdraw.transactionPassword.length
        },
        ps2_ok() {
            this.loading2 = true
            if (this.input_length == 6) {
                axios.post(global.url + '/userPoint/withdraw', this.withdraw, {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '提交成功！即将刷新页面'
                        })
                        this.timer = setTimeout(this.f5, 1500)
                    } else {
                        this.loading2 = false;
                        this.$message("申请提现失败！" + response.data.msg);
                        this.ps = false
                        this.ps_2 = false
                        this.withdraw.transactionPassword = null
                    }
                }).catch(error => {
                    this.loading2 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                    this.ps = false
                    this.ps_2 = false
                    this.withdraw.transactionPassword = null
                })
            } else {
                this.loading2 = false;
                this.$message("交易密码有误！");
                this.withdraw.transactionPassword = null
            }
        },
        f5() {
            this.$router.go(0)
        },
        handleSelection(option) {
            if (this.disabledOptions.includes(option)) {
                this.selectedValue = '支付宝'
                this.$message("提现接口维护中")
            }
            if (this.selectedValue == '支付宝') {
                if (Cookies.get("role") == 'vip') {
                    this.options_num = [1, 10, 50, 100, 200]
                } else {
                    this.options_num = [1, 10, 50, 100, 200, 500, 1000]
                }

            } else if (this.selectedValue == 'USDT') {
                if (Cookies.get("role") == 'vip') {
                    this.options_num = [50, 100, 200]
                } else {
                    this.options_num = [50, 100, 200, 500, 1000]
                }
            }
        },
        handleSelection_num(option) {
            if (this.disabledOptions_num.includes(option)) {
                this.selectedValue_num = ''
            }
        },
        fetchData() {
            this.loading1 = true;
            this.loading4 = true
            axios.get(global.url + '/user/my', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {
                    this.loading1 = false;
                    this.ktx = response.data.totalPoints / 10000;

                } else {
                    this.loading1 = false;
                    this.$message("余额获取失败！" 
                    + response.data.msg);
                }
            }).catch(error => {
                this.loading1 = false;
                this.$message("错误！检查网络或联系客服" + error);
            })
            axios.get(global.url + '/userPoint/withdrawRecord', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {
                    this.tableData = response.data.rows
                    for (this.i = 0; this.i < this.tableData.length; this.i++) {
                        if (this.tableData[this.i].status == 'pending') {
                            this.tableData[this.i].status = '核审中'
                        } else if (this.tableData[this.i].status == 'approved') {
                            this.tableData[this.i].status = '通过'
                        } else if (this.tableData[this.i].status == 'rejected') {
                            this.tableData[this.i].status = '拒绝'
                        }
                        this.tableData[this.i].requestAmount = this.tableData[this.i].requestAmount
                        this.tableData[this.i].actualAmount = this.tableData[this.i].actualAmount
                        this.tableData[this.i].fee = this.tableData[this.i].fee
                    }
                    this.loading4 = false;
                } else {
                    this.loading4 = false;
                    this.$message("提现记录获取失败！" + response.data.msg);
                }
            }).catch(error => {
                this.loading4 = false;
                this.$message("错误！检查网络或联系客服" + error);
            })
            if (Cookies.get("role") == 'vip') {
                this.options_num = [1, 10, 50, 100, 200]
            } else {
                this.options_num = [1, 10, 50, 100, 200, 500, 1000]
            }
        }
    },
    mounted() {
        this.fetchData();
    }
}

</script>

<style lang="scss" scoped>
.main {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.ps {
    position: absolute;
    top: 10vw;
    left: 15vw;
    width: 30vw;
    height: 20vw;
    background-color: grey;
    border-radius: 1vw;
    z-index: 10;
    transition: opacity 0.5s ease;
}

/* 定义过渡效果 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.ps1 {
    position: relative;
    width: 30vw;
    height: 20vw;
}

.ps_title {
    position: absolute;
    width: 20vw;
    height: 2vw;
    left: 5vw;
    top: 2vw;
    font-size: 2vw;
    font-family: ziti3;
    background-color: transparent;
    color: white;
    text-align: center;
}

.ps_info {
    position: absolute;
    width: 26vw;
    height: 10vw;
    top: 5vw;
    left: 2vw;
    font-size: 1.5vw;
    font-family: ziti3;
    color: white;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ps_input {
    position: absolute;
    width: 20vw;
    height: 2vw;
    top: 7vw;
    font-size: 1vw;
    left: 5vw;
}

.ps_bt_cancle {
    position: absolute;
    width: 5vw;
    height: 2vw;
    right: 7vw;
    top: 17vw;
    font-size: 1.3vw;
    font-family: ziti3;
    border: none;
    border-radius: 1vw;
    background-color: #53aaa7;
    color: white;
    text-align: center;
}

.ps_bt_cancle:hover {
    background-color: grey;
}

.ps_bt_ok {
    position: absolute;
    width: 5vw;
    height: 2vw;
    right: 1vw;
    top: 17vw;
    font-size: 1.3vw;
    font-family: ziti3;
    border: none;
    border-radius: 1vw;
    background-color: #53aaa7;
    color: white;
    text-align: center;
}

.ps_bt_ok:hover {
    background-color: grey;
}

.ps2 {
    position: relative;
    width: 30vw;
    height: 20vw;
}

.ketixian {
    position: relative;
    width: 100%;
    height: 15%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1rem;
}

.ketixian_zz {
    position: absolute;
    top: 10%;
    left: 32.5%;
    width: 35%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ktx_wz {
    width: 100%;
    height: 30%;
    text-align: center;
    color: rgb(245, 242, 81);
    font-size: 2vw;
    font-family: ziti3;
    margin-bottom: 0.8vw;
}

.ktx_num {
    position: relative;
    width: 45%;
    height: 30%;
    margin-left: 25%;
}

.jinbi {
    position: absolute;
    top: 13%;
    left: 3%;
    height: 80%;
    width: 17%;
}

.jinbi_num {
    position: absolute;
    left: 30%;
    height: 70%;
    width: 120%;
    font-size: 1.8vw;
    font-family: ziti3;
    color: rgb(245, 242, 81);
    line-height: 2vw;
}

.tixian {
    margin-top: 1%;
    width: 100%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
}

.jilu {
    margin-top: 1%;
    width: 100%;
    height: 53%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.jl_wz {
    width: 96%;
    height: 3%;
    margin-left: 2%;
    margin-top: -2%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.list {
    margin-left: 5%;
    width: 90%;
    height: 85%;
    font-family: ziti3;
}

.el-table {
    background-color: transparent;
}

::v-deep .el-table .el-table__row:hover {
    color: black !important;
}

.da {
    color: white;
    width: 100%;
    height: 30%;
    font-size: 1.6vw;
    font-family: ziti3;
}

.xiao {
    color: white;
    width: 100%;
    height: 15%;
    font-size: 1.2vw;
    font-family: ziti3;
}

.fangshi {
    margin-left: 2%;
    width: 96%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.zz {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.custom-radio {
    display: inline-block;
    padding: 0.5vw 1vw;
    margin-right: 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    border: 0.2vw solid #ccc;
    color: white;
    font-size: 1.5vw;
    font-family: ziti3;
    transition: all 0.3s;
}

.custom-radio:hover {
    background-color: grey
}

.radio-input {
    display: none;
}

.selected-radio {
    background-color: rgba(0, 0, 0, 0.8);
    border-color: #53aaa7;
    color: white;
}

.disabled-radio {
    cursor: not-allowed;
    opacity: 0.6;
}

.icon {
    width: 3vw;
    height: 3vw;
    margin-left: -10%;
}

.custom-radio_num {
    width: 7vw;
    height: 3.5vw;
    display: inline-block;
    padding: 0.5vw 1vw;
    margin-right: 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    border: 0.2vw solid grey;
    text-align: center;
    line-height: 3vw;
    color: goldenrod;
    font-size: 1.3vw;
    transition: all 0.3s;
}

.custom-radio_num:hover {
    background-color: grey
}

.radio-input_num {
    display: none;
}

.selected-radio_num {
    border-color: goldenrod;
    background-color: black;
}

.num_wz {
    display: flex;
    flex-direction: column;
}

.num_da {
    font-size: 1.7vw;
    font-family: ziti3;
}

.num_xiao {
    margin-top: -25%;
    font-size: 1vw;
    font-family: ziti1;
    color: grey;
}

.fs_wz {
    position: relative;
    width: 100%;
    font-size: 1vw;
    text-align: center;
}

.xiao_zhifubao {
    position: absolute;
    left: 18%;
    width: 5%;
    color: red;
}

.xiao_weixin {
    position: absolute;
    left: 47%;
    width: 5%;
    color: dimgray;
}

.xiao_USDP {
    position: absolute;
    left: 76%;
    width: 5%;
    color: dimgray;
}

.zz_1 {
    width: 100%;
    margin-top: 2%;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tixian_button_zz {
    width: 100%;
    height: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tixian_button {
    width: 15%;
    height: 100%;
    font-size: 1.4vw;
    font-family: ziti3;
    border: none;
    border-radius: 2vw;
    color: white;
    background-color: #53aaa7;
}

.tixian_button:hover {
    background-color: rgb(125, 48, 48);
}

.num {
    margin-left: 2%;
    margin-top: 1%;
    width: 96%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.shuoming {
    margin-left: 2%;
    margin-top: 1%;
    width: 96%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.tot {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(12px);
    border-radius: 1vw;
    border: 0.15vw solid #53aaa7;
    top: 6vw;
    right: -9.5vw;
    width: 12vw;
    height: 20vw;
    transition: all 0.3s;
}

.tot_title {
    position: absolute;
    width: 12vw;
    top: 0.5vw;
    color: #53aaa7;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tot_fee {
    position: absolute;
    width: 12vw;
    top: 2vw;
    color: #53aaa7;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tot_actualAmount {
    position: absolute;
    width: 12vw;
    top: 3.5vw;
    color: #53aaa7;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tot_cheak {
    position: absolute;
    width: 10vw;
    left: 1vw;
    top: 5vw;
    text-align: center;
    color: white;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tot_cheaktime {
    position: absolute;
    width: 12vw;
    text-align: center;
    top: 6.5vw;
    color: white;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tot_remark {
    position: absolute;
    width: 12vw;
    text-align: center;
    top: 8vw;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
}

.tot_context {
    position: absolute;
    background-color: transparent;
    width: 10vw;
    height: 9vw;
    left: 1vw;
    top: 10vw;
    border-radius: 0vw 0vw 1vw 1vw;
    border: 0.15vw solid #53aaa7;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
}
</style>