<template>
    <div class="main" v-on:load="loading">
        <div class="window">
            <router-view @data-from-gouka="handleDataFromChild" class="card_st" />
            <transition name="fade">
                <div v-if="tot_flag" class="tot">
                    <button class="tot_bt" @click="tot_close_fc"><i class="el-icon-close"></i></button>
                    <span class="tot_title">订 单 详 细</span>
                    <span class="tot_found">支付方式：{{ tot_found }}</span>
                    <span class="tot_type">购买类型：{{ tot_type }}</span>
                    <span class="tot_num">购买数量：{{ tot_num }}</span>
                    <span class="tot_amount">支付金额：{{ tot_amount }}</span>
                    <span class="tot_time">生成时间：{{ tot_time }}</span>
                    <span class="tot_ts">*务必记住下方订单号，使用订单号在查询页面查询而核审结果</span>
                    <div class="tot_id_zz">
                        <span class="tot_id_title">订单号</span>
                        <span ref="textToCopy" class="tot_id">{{ tot_id }}</span>
                        <button class="tot_copy" @click="copy"> 复制 </button>
                    </div>
                </div>
            </transition>
            <div class="info_st">
                <div class="prompt">
                    <div class="prompt_zz">
                        <span class="large">购卡须知：</span>
                        <span class="small">1 一张会员卡，仅能开通一个端口</span>
                        <span class="small">2 如需购买多张，请在购买数量处加到相应数量</span>
                        <span class="small">3 如遇卡已售空，请联系客服</span>
                    </div>
                </div>
                <div class="number">
                    <div class="number_wz">
                        <span class="large">购买数量：</span>
                    </div>
                    <div class="counter-container">
                        <button @click="decrease" :disabled="num <= minValue" class="counter-button">-</button>
                        <input type="text" v-model="num" @input="validateInput" class="counter-input" />
                        <button @click="increase" :disabled="num >= maxValue" class="counter-button">+</button>
                    </div>
                </div>
                <div class="function">
                    <div class="function_wz">
                        <span class="large">支付方式：</span>
                    </div>
                    <div class="function_option">
                        <label v-for="option in options" :key="option" :class="['custom-radio', {
                            'selected-radio': selectedValue === option.name,
                            'disabled-radio': disabledOptions.includes(option.name)
                        }]">
                            <input type="radio" :value="option.name" v-model="selectedValue" class="radio-input"
                                @change="handleSelection(option.name)">
                            <img class="fc_pic" :src="option.url" />
                            {{ option.name }}
                        </label>
                    </div>
                </div>
                <div class="picture">
                    <div class="picture_wz">
                        <span class="large_1">您应该支付{{ zhifu_num }}{{ zhifu_dw }}</span>
                    </div>
                    <!-- <img v-if="this.selectedValue == '支付宝'" :src="this.zhifu_pic[this.zhifu_pic_num].url"
                        :style="{ filter: mohu === true ? 'blur(5px)' : 'none' }" class="picture_pic" />

                    <img v-if="this.selectedValue == '微信'" :src="this.weixin_pic[this.weixin_pic_num].url"
                        :style="{ filter: mohu === true ? 'blur(5px)' : 'none' }" class="picture_pic" /> -->

                    <img v-if="this.selectedValue == '支付宝'" :src="this.zhifu_pic[this.zhifu_pic_num].url"
                        class="picture_pic" />

                    <img v-if="this.selectedValue == '微信'" :src="this.weixin_pic[this.weixin_pic_num].url"
                        class="picture_pic" />


                    <button v-if="!mohu_wz && !mohu" class="mohu_button" @click="kefu_fc">联系代理购买</button>


                    <span class="picture_pic_tishi_1"
                        v-if="(this.selectedValue == '微信' || this.selectedValue == '支付宝') && !this.mohu && !this.mohu_wz">支付后请点击[立即购买]</span>
                    <span class="picture_pic_tishi_2"
                        v-if="(this.selectedValue == '微信' || this.selectedValue == '支付宝') && !this.mohu && !this.mohu_wz">使用[立即购买]后生成的订单号进行[查询订单]</span>

                    <div v-if="mohu_wz && mohu" class="blur-message">
                        请在左侧选择购买类型
                    </div>

                    <button v-if="!mohu_wz && mohu" class="mohu_button" @click="mohu_button_fc">获取支付二维码</button>

                    <span v-if="this.selectedValue == 'USDT'" class="picture_pic_usdt_url_1">仅支持TRX/TRC20的相关资产</span>
                    <img v-if="this.selectedValue == 'USDT'" 
                        src="https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/usdt3.png"
                        class="picture_pic_usdt" />
                    <!-- <img v-if="this.selectedValue == 'USDT'" :style="{ filter: mohu === true ? 'blur(5px)' : 'none' }"
                        src="https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/usdt3.png"
                        class="picture_pic_usdt" /> -->
                    <!-- <span v-if="this.selectedValue == 'USDT' && !this.mohu && !this.mohu_wz"
                        class="picture_pic_usdt_url">TXDeiwVDSgSWL5fRLZ9T3uRjzsbX4tnfwa</span>
                    <span v-if="this.selectedValue == 'USDT' && !this.mohu && !this.mohu_wz"
                        class="picture_pic_usdt_url_wz">请务必核对向客服核对链接的每一位,并在下方提交购买截图</span> -->
                </div>
                <div class="order">
                    <!-- <div class="order_wz">
                        <span v-if="this.selectedValue == 'USDT'" class="large">购买凭证：</span>
                    </div>
                    <div class="order_input">

                        <input class="usdt_change" v-if="this.selectedValue == 'USDT'" type="file" accept="image/*"
                            @change="handlePicChange" ref="PicInput" />
                        <button class="usdt_upload" v-if="this.selectedValue == 'USDT'" @click="uploadPic">{{
                            usdt_order_wz }}</button>

                    </div> -->
                </div>
                <div class="button">
                    <div class="buttons">
                        <!-- <router-link to="">
                            <button v-if="!find_status" class="buy" @click="buy">立即购买</button>
                        </router-link> -->
                        <!-- <router-link to="/getvip/findId">
                            <button v-if="!find_status" class="buy1" @click="find_status = !find_status">查询订单</button>
                        </router-link> -->
                        <router-link to="/getvip/findId">
                            <button v-if="!find_status" class="buy" @click="find_status = !find_status">查询订单</button>
                        </router-link>
                        <router-link to="/getvip/">
                            <button v-if="find_status" class="buy" @click="find_status = !find_status">返回购买</button>
                        </router-link>
                        <!-- <router-link to="">
                            <button type="info" class="buy2" @click="kefu_fc">联系客服</button>
                        </router-link> -->
                    </div>
                    <span class="bt_prompt">如无法购买，请联系客服处理</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Gouka from "../../components/huoquhuiyuanka/gouka.vue";
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    components: {
        Gouka
    },
    name: 'getvip',
    data() {
        return {
            mohu: true,
            mohu_wz: true,

            u_HL: 7.2,
            zhifu_dw: '￥',
            usdt_url: null,
            usdt_flag: false,
            usdt_order_wz: '未提交',
            find_status: false,
            gouka: 0,
            zhifu_num: 0,
            u_num: 0,
            z_num: 0,
            num: 1,
            radio: '1',
            selectedValue: '支付宝',
            options: [
                {
                    name: '支付宝',
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/zfb.png'
                }, {
                    name: '微信',
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/wx.png'
                }, {
                    name: 'USDT',
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/USDT.png'
                }
            ],
            zhifu_pic_num: null,
            zhifu_pic: [
                {
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/zhifu12.jpg'
                }, {
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/zhifu13.jpg'
                }, {
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/zhifu9.jpg'
                }
            ],
            weixin_pic_num: null,
            weixin_pic: [
                {
                    url: 'https://rongshihui.cdn2.cc/files/assets/huoquhuiyuanka/weixin1.jpg'
                }
            ],
            disabledOptions: [],
            minValue: 1,
            maxValue: 20,
            buy_info: {
                membershipCardType: null,
                paymentMethod: null,
                paymentOrderNum: null,
                quantity: 0,
                orderId: null,
            },
            loading: false,

            tot_flag: false,
            tot_found: null,
            tot_type: null,
            tot_num: null,
            tot_amount: null,
            tot_time: null,
            tot_id: null,
            kefu: {
                visiter_id: null,
                visiter_name: null,
                role: null,
                isActived: null,
                totalPoints: null,
                inviteCode: null,
                spentPoints: null,
                teamTotal: null,
                groupid: null,
            },
            kefu_url: null,
            selectedPic: null,
            orderId: null,
        }
    },
    methods: {
        mohu_button_fc() {
            this.$confirm('请您再次确认你已经选择正确的支付方式、购买张数、购买类型，接下来请根据二维码上方红色字体提示的金额付款，然后点击立即购买。', '购买须知', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.buy_info.quantity = this.num

                axios.post(global.url + '/buyMembershipCard', this.buy_info, {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                })
                    .then(response => {
                        console.log(response)
                        this.orderId = response.data.data
                    })
                    .catch(error => {
                        console.log(error)
                    });

                this.mohu = false
            }).catch(() => {
                this.$message("取消获取支付二维码")
            })
        },
        kefu_fc() {
            this.$message("请联系代理")
        },
        copy() {
            const textElement = this.$refs.textToCopy; // 获取要复制的文本所在的 div 元素

            // 创建一个临时的输入框来执行复制操作
            const range = document.createRange();  // 创建一个 Range 对象
            range.selectNodeContents(textElement);  // 选中要复制的内容

            const selection = window.getSelection(); // 获取当前的 Selection 对象
            selection.removeAllRanges();  // 清除现有的选区
            selection.addRange(range);  // 设置新的选区范围

            try {
                // 执行复制操作
                const successful = document.execCommand('copy');
                if (successful) {
                    this.$message("复制成功")
                }
            } catch (err) {
                this.$message("复制失败" + err)
            }

            // 清除选区
            selection.removeAllRanges();
        },
        tot_close_fc() {
            this.tot_flag = !this.tot_flag
        },
        uploadPic() {
            if (this.selectedPic == null) {
                this.$message("请先选择一张图片!")
                return
            }

            const formData = new FormData
            formData.append("file", this.selectedPic)

            if (!this.usdt_flag) {
                axios.post(global.url + '/buyMembershipCard/upload', formData, {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code == 200) {
                        this.$message("上传图片成功！")
                        this.usdt_flag = true
                        this.usdt_url = response.data.url
                        this.usdt_order_wz = '提交成功'
                    } else {
                        this.usdt_flag = false
                        this.$message("上传图片失败！" + response.data.msg)
                    }
                }).catch(error => {
                    this.usdt_flag = false
                    this.$message("网络错误！请检查网络或联系客服" + error.msg)
                })
            } else {
                this.$message("请重新选择图片！")
            }
        },
        handlePicChange(event) {
            const file = event.target.files[0]

            if (file && file.type.startsWith('image/')) {
                this.selectedPic = file
                this.$message("已选择图片名称：" + file.name)
                this.usdt_order_wz = '未提交'
                this.usdt_url = null
                if (this.usdt_flag) {
                    this.usdt_flag = false
                }
            } else {
                this.$message("请选择一张有效的图片文件")
                this.usdt_order_wz = '未提交'
                this.usdt_url = null
                this.selectedPic = null
            }
        },
        buy() {
            if (this.mohu || this.mohu_wz) {
                this.$message("请先获取上方二维码")
                return
            }
            this.$confirm('请使用支付订单号在查询页面查询订单！若批量购买，建议统一购买完成后，统一在查询页面查询。', '购卡后须知', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                if (this.selectedValue == '支付宝') {
                    this.$prompt('请输入支付宝订单后8位数字', '支付凭证', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputPattern: /^\d{8}$/,
                        inputErrorMessage: '请输入8位数字'
                    }).then(({ value }) => {
                        this.buy_info.paymentOrderNum = value
                        this.buy_info.orderId = this.orderId
                        if ((this.buy_info.membershipCardType != null)) {
                            axios.put(global.url + '/buyMembershipCard?orderId=' + this.buy_info.orderId + '&paymentOrderNum=' + this.buy_info.paymentOrderNum, {
                                headers: { Authorization: "Bearer " + Cookies.get("token") }
                            })
                                .then(response => {
                                    if (response.data.code == '200') {
                                        this.$message({
                                            type: 'success',
                                            message: '下单成功!'
                                        });
                                        this.tot_found = this.selectedValue
                                        if (this.buy_info.membershipCardType == 'month_card') {
                                            this.tot_type = '月卡'
                                        } else if (this.buy_info.membershipCardType == 'year_card') {
                                            this.tot_type = '年卡'
                                        } else if (this.buy_info.membershipCardType == 'season_card') {
                                            this.tot_type = '季卡'
                                        }
                                        this.tot_num = this.buy_info.quantity
                                        this.tot_amount = this.zhifu_num
                                        this.tot_id = response.data.msg
                                        axios.get(global.url + "/buyMembershipCard?paymentKey=" + this.tot_id)
                                            .then(response => {
                                                this.loading = false
                                                if (response.data.code == 200) {
                                                    this.tot_time = response.data.data.updatedAt
                                                    if (!this.mohu) {
                                                        this.mohu = true
                                                    }
                                                } else {
                                                    this.$message("订单生成失败" + response.data.msg)
                                                }
                                            }).catch(error => {
                                                this.loading = false;
                                                this.$message("网络错误1！请检查网络或联系客服" + error.msg);
                                            })
                                        this.tot_flag = true;

                                    } else {
                                        this.loading = false;
                                        this.$message(response.data.msg);
                                    }
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.$message("网络错误2！请检查网络或联系客服" + error.msg);
                                });
                        } else {
                            this.$message("未选择购买类型！！！")
                        }
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消输入支付凭证'
                        });
                    });

                } else if (this.selectedValue == 'USDT') {
                    this.buy_info.paymentOrderNum = this.usdt_url

                    this.buy_info.orderId = this.orderId
                    if ((this.buy_info.membershipCardType != null) && (this.buy_info.paymentMethod != null) && (this.buy_info.quantity != 0) && (this.usdt_flag) && (this.buy_info.paymentOrderNum != null)) {
                        axios.put(global.url + '/buyMembershipCard?orderId=' + this.buy_info.orderId + '&paymentOrderNum=' + this.buy_info.paymentOrderNum, {
                            headers: { Authorization: "Bearer " + Cookies.get("token") }
                        })
                            .then(response => {
                                if (response.data.code == '200') {
                                    this.$message({
                                        type: 'success',
                                        message: '下单成功!'
                                    });
                                    this.tot_found = this.selectedValue
                                    if (this.buy_info.membershipCardType == 'month_card') {
                                        this.tot_type = '月卡'
                                    } else if (this.buy_info.membershipCardType == 'year_card') {
                                        this.tot_type = '年卡'
                                    } else if (this.buy_info.membershipCardType == 'season_card') {
                                        this.tot_type = '季卡'
                                    }
                                    this.tot_num = this.buy_info.quantity
                                    this.tot_amount = this.zhifu_num
                                    this.tot_id = response.data.msg
                                    axios.get(global.url + "/buyMembershipCard?paymentKey=" + this.tot_id)
                                        .then(response => {
                                            this.loading = false
                                            if (response.data.code == 200) {
                                                this.tot_time = response.data.data.updatedAt
                                                if (!this.mohu) {
                                                    this.mohu = true
                                                }
                                            } else {
                                                this.$message("订单生成失败" + response.data.msg)
                                            }
                                        }).catch(error => {
                                            this.loading = false;
                                            this.$message("网络错误！请检查网络或联系客服" + error.msg);
                                        })
                                    this.tot_flag = true;
                                } else {
                                    this.loading = false;
                                    this.$message(response.data.msg);
                                }
                            })
                            .catch(error => {
                                this.loading = false;
                                this.$message("网络错误！请检查网络或联系客服" + error.msg);
                            });
                    } else {
                        if (this.buy_info.membershipCardType == null) {
                            this.$message("未选择购买类型！！！")
                        } else {
                            this.$message("图片未提交！！！")
                        }
                    }
                } else if (this.selectedValue == '微信') {
                    this.$prompt('请输入微信订单后8位数字', '支付凭证', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        inputPattern: /^\d{8}$/,
                        inputErrorMessage: '请输入8位数字'
                    }).then(({ value }) => {
                        this.buy_info.paymentOrderNum = value
                        this.buy_info.orderId = this.orderId
                        if ((this.buy_info.membershipCardType != null)) {
                            axios.put(global.url + '/buyMembershipCard?orderId=' + this.buy_info.orderId + '&paymentOrderNum=' + this.buy_info.paymentOrderNum, {
                                headers: { Authorization: "Bearer " + Cookies.get("token") }
                            })
                                .then(response => {
                                    if (response.data.code == '200') {
                                        this.$message({
                                            type: 'success',
                                            message: '下单成功!'
                                        });
                                        this.tot_found = this.selectedValue
                                        if (this.buy_info.membershipCardType == 'month_card') {
                                            this.tot_type = '月卡'
                                        } else if (this.buy_info.membershipCardType == 'year_card') {
                                            this.tot_type = '年卡'
                                        } else if (this.buy_info.membershipCardType == 'season_card') {
                                            this.tot_type = '季卡'
                                        }
                                        this.tot_num = this.buy_info.quantity
                                        this.tot_amount = this.zhifu_num
                                        this.tot_id = response.data.msg
                                        axios.get(global.url + "/buyMembershipCard?paymentKey=" + this.tot_id)
                                            .then(response => {
                                                this.loading = false
                                                if (response.data.code == 200) {
                                                    this.tot_time = response.data.data.updatedAt
                                                    if (!this.mohu) {
                                                        this.mohu = true
                                                    }
                                                } else {
                                                    this.$message("订单生成失败" + response.data.msg)
                                                }
                                            }).catch(error => {
                                                this.loading = false;
                                                this.$message("网络错误！请检查网络或联系客服" + error.msg);
                                            })
                                        this.tot_flag = true;

                                    } else {
                                        this.loading = false;
                                        this.$message(response.data.msg);
                                    }
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.$message("网络错误！请检查网络或联系客服" + error.msg);
                                });
                        } else {
                            this.$message("未选择购买类型！！！")
                        }
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消输入支付凭证'
                        });
                    });

                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消购买！'
                });
            });


        },
        handleDataFromChild(data) {
            this.z_num = data * this.num
            this.u_num = this.z_num / this.u_HL
            this.u_num = this.u_num.toFixed(2)
            if (this.selectedValue == 'USDT') {

                this.zhifu_num = this.u_num
            } else if (this.selectedValue == '支付宝') {

                this.zhifu_num = this.z_num
            } else if (this.selectedValue == '微信') {
                this.zhifu_num = this.z_num
            }


            if (this.zhifu_num == 0) {
                this.mohu_wz = true
                this.mohu = true
            } else {
                this.mohu_wz = false
                if (!this.mohu) {
                    this.mohu = true
                }
            }
            this.gouka = data;
            if (data == 139) {
                this.buy_info.membershipCardType = 'month_card'
            } else if (data == 450) {
                this.buy_info.membershipCardType = 'season_card'
            } else if (data == 1620) {
                this.buy_info.membershipCardType = 'year_card'
            } else {
                this.buy_info.membershipCardType = null
            }
            this.buy_info.quantity = this.num
            if (this.selectedValue == '支付宝') {
                this.buy_info.paymentMethod = 'alipay'
            } else if (this.selectedValue == 'USDT') {
                this.buy_info.paymentMethod = 'usdt'
            } else if (this.selectedValue == '微信') {
                this.buy_info.paymentMethod = 'wechat'
            }
        },
        handleSelection(option) {
            if (this.disabledOptions.includes(option)) {
                this.selectedValue = '支付宝'
                this.$message("支付方式维护中")
            }
            if (!this.mohu) {
                this.mohu = true
            }
            if (this.selectedValue == 'USDT') {
                this.$confirm('使用USDT购买，请联系并要求代理向客服核对USDT链接等信息！如若出现支付未到账，概不负责！', 'USDT使用须知', {
                    confirmButtonText: '同意',
                    cancelButtonText: '不同意',
                    type: 'warning'
                }).then(() => {
                    this.zhifu_dw = '$'
                    this.zhifu_num = this.u_num
                }).catch(() => {
                    this.selectedValue = '支付宝'
                    this.zhifu_dw = '￥'
                    this.zhifu_num = this.z_num
                })

            } else if (this.selectedValue == '支付宝') {
                this.zhifu_dw = '￥'
                this.zhifu_num = this.z_num
            } else if (this.selectedValue == '微信') {
                this.zhifu_dw = '￥'
                this.zhifu_num = this.z_num
            }


            if (this.selectedValue == '支付宝') {
                this.buy_info.paymentMethod = 'alipay'
            } else if (this.selectedValue == 'USDT') {
                this.buy_info.paymentMethod = 'usdt'
            } else if (this.selectedValue == '微信') {
                this.buy_info.paymentMethod = 'wechat'
            }
        },
        increase() {
            if (this.num < this.maxValue) {
                this.num++;
                this.z_num = this.gouka * this.num;
                this.u_num = this.z_num / this.u_HL
                this.u_num = this.u_num.toFixed(2)
            }
            if (this.selectedValue == 'USDT') {
                this.zhifu_num = this.u_num
            } else if (this.selectedValue == '支付宝') {
                this.zhifu_num = this.z_num
            } else if (this.selectedValue == '微信') {
                this.zhifu_num = this.z_num
            }


            if (!this.mohu) {
                this.mohu = true
            }
        },
        decrease() {
            if (this.num > this.minValue) {
                this.num--;
                this.z_num = this.gouka * this.num;
                this.u_num = this.z_num / this.u_HL
                this.u_num = this.u_num.toFixed(2)
            }
            if (this.selectedValue == 'USDT') {
                this.zhifu_num = this.u_num
            } else if (this.selectedValue == '支付宝') {
                this.zhifu_num = this.z_num
            } else if (this.selectedValue == '微信') {
                this.zhifu_num = this.z_num
            }


            if (!this.mohu) {
                this.mohu = true
            }
        },
        validateInput() {
            const value = parseInt(this.num)
            if (isNaN(value) || value < this.minValue) {
                this.num = this.minValue
                this.z_num = this.gouka * this.num;
                this.u_num = this.z_num / this.u_HL
                this.u_num = this.u_num.toFixed(2)
            } else if (value > this.maxValue) {
                this.num = this.maxValue
                this.z_num = this.gouka * this.num;
                this.u_num = this.z_num / this.u_HL
                this.u_num = this.u_num.toFixed(2)
            } else {
                this.num = value
                this.z_num = this.gouka * this.num;
                this.u_num = this.z_num / this.u_HL
                this.u_num = this.u_num.toFixed(2)
            }

            if (this.selectedValue == 'USDT') {
                this.zhifu_num = this.u_num
            } else if (this.selectedValue == '支付宝') {
                this.zhifu_num = this.z_num
            } else if (this.selectedValue == '微信') {
                this.zhifu_num = this.z_num
            }


            if (!this.mohu) {
                this.mohu = true
            }
        }
    },
    mounted() {
        this.zhifu_pic_num = Math.floor(Math.random() * 3)
        this.weixin_pic_num = Math.floor(Math.random() * 1)
        if (this.$router.path != '/getvip') {
            this.$router.push({ path: '/getvip' }).catch(err => { err })
        }

        axios.get(global.url + '/user/my', {
            headers: { Authorization: "Bearer " + Cookies.get("token") }
        }).then(response => {
            if (response.data.code != 401) {
                this.kefu.visiter_id = response.data.userId
                this.kefu.visiter_name = response.data.userName
                this.kefu.totalPoints = response.data.totalPoints / 10000
                this.kefu.inviteCode = response.data.inviteCode
                this.kefu.isActived = response.data.isActive
                this.kefu.role = response.data.roleId
                this.kefu.teamTotal = response.data.teamTotal
                this.kefu.spentPoints = response.data.spentPoints / 10000
                this.kefu.groupid = 6
                if (this.kefu.role == 13) {
                    this.kefu.role = '总代理'
                } else if (this.kefu.role == 12) {
                    this.kefu.role = '高级代理'
                } else if (this.kefu.role == 11) {
                    this.kefu.role = '初级代理'
                } else if (this.kefu.role == 10) {
                    if (this.kefu.isActived == 1) {
                        this.kefu.role = 'VIP'
                    } else {
                        this.kefu.role = '普通用户'
                    }
                }

                if (this.kefu.isActived == 1) {
                    this.kefu.isActived = '已激活'
                } else {
                    this.kefu.isActived = '未激活'
                }
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
            } else {
                this.$message("获取失败！请登录");
                this.$router.push({ path: '/login' });
            }
        }).catch(error => {
            this.$message("错误！检查网络或联系客服" + error);
        })
    }
}

</script>

<style long="scss" scoped>
.main {
    position: relative;
    width: 100vw;
    height: 53.9vw;
}

.window {
    position: absolute;
    left: 10vw;
    top: 4vw;
    width: 80vw;
    height: 45vw;
    background-color: rgba(16, 15, 15, 0.463);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.card_st {
    width: 40vw;
    height: 45vw;
}

.tot {
    position: absolute;
    top: 5vw;
    width: 30vw;
    height: 30vw;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 2vw;
    border: 0.15vw solid #53aaa7;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.tot_bt {
    position: absolute;
    font-size: 1.4vw;
    right: 0.5vw;
    top: 0.5vw;
    color: #53aaa7;
    border: none;
    z-index: 10;
    background-color: transparent;
}

.tot_title {
    position: absolute;
    color: white;
    font: 2vw bold;
    left: 9.5vw;
    font-family: ziti3;
    top: 1vw;
}

.tot_found {
    position: absolute;
    color: white;
    font-size: 1.7vw;
    font-family: ziti3;
    top: 4vw;
    left: 2vw;
}

.tot_type {
    position: absolute;
    color: white;
    font-size: 1.7vw;
    font-family: ziti3;
    top: 6vw;
    left: 2vw;
}

.tot_num {
    position: absolute;
    color: white;
    font-size: 1.7vw;
    font-family: ziti3;
    top: 8vw;
    left: 2vw;
}

.tot_amount {
    position: absolute;
    color: white;
    font-size: 1.7vw;
    font-family: ziti3;
    top: 10vw;
    left: 2vw;
}

.tot_time {
    position: absolute;
    color: white;
    font-size: 1.7vw;
    font-family: ziti3;
    top: 12vw;
    left: 2vw;
}

.tot_ts {
    position: absolute;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
    top: 14.8vw;
    left: 1.2vw;
}

.tot_id_zz {
    position: absolute;
    width: 26vw;
    height: 12vw;
    top: 16vw;
    left: 2vw;
    border: 0.15vw solid #53aaa7;
    border-radius: 2vw;
}

.tot_id_title {
    position: absolute;
    color: white;
    width: 26vw;
    top: 0.5vw;
    text-align: center;
    font-size: 2vw;
    font-family: ziti3;
}

.tot_id {
    position: absolute;
    width: 26vw;
    height: 5vw;
    top: 4.5vw;
    text-align: center;
    font-size: 3vw;
    font-family: ziti3;
    color: #53aaa7;
}

.tot_copy {
    position: absolute;
    top: 9.5vw;
    right: 0.7vw;
    width: 4vw;
    height: 2vw;
    border-radius: 2vw;
    font-size: 1.3vw;
    font-family: ziti3;
    color: white;
    background-color: #53aaa7;
    border: none;
}

.tot_copy:hover {
    background-color: grey;
}

/* 定义过渡效果 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.info_st {
    width: 40vw;
    height: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.prompt {
    width: 40vw;
    height: 7.93vw;
}

.prompt_zz {
    margin-left: 4vw;
    width: 32vw;
    height: 7.93vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.large {
    font-size: 1.6vw;
    font-family: ziti3;
    color: white;
}

.small {
    font-size: 1.2vw;
    font-family: ziti3;
    color: white;
}

.number {
    position: relative;
    width: 40vw;
    height: 5.3vw;
}

.number_wz {
    position: absolute;
    width: 12vw;
    height: 2.65vw;
    top: 0.5vw;
    left: 4vw;
}

.counter-container {
    position: absolute;
    height: 2vw;
    width: 20vw;
    left: 11vw;
    top: 0.5vw;
    display: flex;
    align-items: center;
}

.counter-button {
    width: 2vw;
    height: 2vw;
    font-size: 1vw;
    cursor: pointer;
    margin: 0 0.5vw;
    border: 0.2vw solid #ccc;
    border-radius: 0.5vw;
    background-color: #f5f5f5;
    transition: backgroung-color 0.3s;
}

.counter-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.counter-input {
    width: 6vw;
    height: 2vw;
    text-align: center;
    font-size: 1.2vw;
    border: 0.5vw;
    border-radius: 0.5vw;
}

.function {
    position: relative;
    width: 40vw;
    height: 2.65vw;
}

.function_wz {
    position: absolute;
    top: -0.5vw;
    left: 10%;
}

.function_option {
    position: absolute;
    width: 25vw;
    height: 3vw;
    top: -0.8vw;
    left: 11vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.custom-radio {
    display: inline-block;
    padding: 0.5vw 1vw;
    margin-right: 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    border: 0.2vw solid #ccc;
    font-size: 1.1vw;
    font-family: ziti3;
    color: white;
    transition: all 0.3s;
}

.custom-radio:hover {
    background-color: grey;
}

.radio-input {
    display: none;
}

.fc_pic {
    width: 1.8vw;
    height: 1.8vw;
}

.selected-radio {
    background-color: black;
    border-color: #53aaa7;
    color: white;
}

.disabled-radio {
    cursor: not-allowed;
    opacity: 0.6;
}

.picture {
    position: relative;
    width: 40vw;
    height: 18.55vw;
}

.picture_wz {
    position: absolute;
    top: 0.3vw;
    left: 15.3vw;
}

.large_1 {
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
    color: rgb(255, 25, 0);
}

.picture_pic {
    position: absolute;
    top: 6vw;
    left: 11.7vw;
    width: 16.5vw;
    height: 17.5vw;
    filter: blur(5px);
}

.picture_pic_tishi_1 {
    position: absolute;
    top: 2vw;
    width: 100%;
    text-align: center;
    color: rgb(229, 190, 47);
    font-size: 1.5vw;
    font-family: ziti3;
}


.picture_pic_tishi_2 {
    position: absolute;
    top: 4vw;
    width: 100%;
    text-align: center;
    color: rgb(229, 190, 47);
    font-size: 1.5vw;
    font-family: ziti3;
}

.blur-message {
    position: absolute;
    top: 11vw;
    width: 100%;
    font-size: 1.5vw;
    font-family: ziti3;
    text-align: center;
    border-radius: 1vw;
    color: white;
    background-color: #53aaa7;
    z-index: 2;
}

.mohu_button {
    position: absolute;
    top: 9.5vw;
    left: 12.3vw;
    width: 15vw;
    height: 5vw;
    font-size: 1.5vw;
    border: none;
    border-radius: 1vw;
    color: white;
    text-align: center;
    background-color: #53aaa7;
    z-index: 2;
}

.mohu_button:hover {
    background-color: grey;
}

.picture_pic_usdt {
    position: absolute;
    top: 4vw;
    left: 11.7vw;
    width: 16.5vw;
    height: 14.5vw;
    filter: blur(5px);
}

.picture_pic_usdt_url_1 {
    position: absolute;
    top: 2vw;
    width: 100%;
    background-color: transparent;
    color: rgb(229, 190, 47);
    text-align: center;
}

.picture_pic_usdt_url {
    position: absolute;
    top: 18.5vw;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;

}

.picture_pic_usdt_url_wz {
    position: absolute;
    top: 20vw;
    width: 100%;
    background-color: transparent;
    color: rgb(229, 190, 47);
    text-align: center;
}

.order {
    position: relative;
    margin-top: 15%;
    width: 40vw;
    height: 5.3vw;
}

.order_wz {
    position: absolute;
    height: 5vw;
    top: 1.4vw;
    left: 4vw;
}

.order_input {
    position: absolute;
    height: 5vw;
    top: 1.4vw;
    left: 11vw;
    width: 24vw;
}

.usdt_change {
    position: absolute;
    top: 0.2vw;
    left: 1.5vw;
    background-color: #53aaa7;
    color: white;
    width: 13vw;
    font-family: ziti3;
}

.usdt_upload {
    position: absolute;
    text-align: center;
    left: 15vw;
    top: 0.2vw;
    background-color: #53aaa7;
    color: white;
    font-family: ziti3;
    font-size: 1.33vw;
    margin-left: 1vw;
    width: 8vw;
    border: none;
}

.usdt_upload:hover {
    background-color: grey;
}

.button {
    width: 40vw;
    height: 7.95vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.buttons {
    position: relative;
    width: 40vw;
    height: 7.95vw;
}

.bt_prompt {
    margin-top: -4.5vw;
    height: 2vw;
    text-align: center;
    font-size: 1.2vw;
    font-family: ziti3;
    color: gray;
    font-weight: bold;
}

.buy {
    position: absolute;
    width: 14vw;
    height: 3vw;
    font-size: 1.8vw;
    font-family: ziti3;
    left: 12.7vw;
    background-color: #53aaa7;
    color: white;
    border: none;
    border-radius: 1.5vw;
}

.buy:hover {
    background-color: grey;
}

.buy1 {
    position: absolute;
    width: 6vw;
    height: 2vw;
    left: 4vw;
    top: 1vw;
    font-size: 1.1vw;
    font-family: ziti3;
    background-color: #53aaa7;
    color: white;
    border: none;
    border-radius: 1.5vw;
}

.buy1:hover {
    background-color: grey;
}

.buy2 {
    position: absolute;
    left: 29.1vw;
    width: 6vw;
    height: 2vw;
    top: 1vw;
    font-size: 1.1vw;
    font-family: ziti3;
    background-color: #53aaa7;
    border: none;
    border-radius: 1.5vw;
    color: white;
}


.buy2:hover {
    background-color: grey;
}
</style>