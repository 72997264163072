<template>
    <div class="main">
        <div class="chuangkou">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import router from '@/router';


export default{
    name: 'YonghuXieyi',
}
</script>

<style long="scss" scoped>
.main{
    width: 100%;
    height: 100%;
}
.chuangkou{
    width: 100%;
    height: 95%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;

    flex: 1;
    overflow: auto;
    box-sizing: border-box;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

</style>