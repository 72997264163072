<template>
    <div class="main">
        <div class="window" v-loading="loading">
            <div class="title">
                <span class="da">用户注册</span>
            </div>
            <div class="info">
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-user"></i>
                    </span>
                    <input v-model="register_info.username" @input="removeInvalidChars" @keydown="preventInvalidChars"
                        placeholder="用户名（仅支持字母+数字）" class="password-input" />
                </div>
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-unlock"></i>
                    </span>
                    <input type='password' v-model="register_info.password" @keydown="preventSpace" placeholder="密码"
                        class="password-input" />
                </div>
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-lock"></i>
                    </span>
                    <input type='password' v-model="register_info.input2" @keydown="preventSpace" placeholder="确认密码"
                        class="password-input" />
                </div>
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-key"></i>
                    </span> 
                    <input type='password' v-model="register_info.transactionPassword" @keydown="preventSpace1"  @input="countInputLength"
                        placeholder="交易密码（请输入6位纯数字）" class="password-input" />
                </div>
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-message"></i>
                    </span>
                    <input v-model="email" placeholder="邮箱" class="password-input" />
                </div>

                <div class="tzm_box">
                    <div class="yzm-container">
                        <span class="yzm-toggle_h">
                            <i class="el-icon-paperclip"></i>
                        </span>
                        <input v-model="register_info.code" placeholder="验证码" class="yzm-input" />
                        <button v-loading="loading1" @click="getyzm" class="yzm-toggle-button">{{ this.yam_wenzi }}</button>
                    </div>
                </div>

                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-warning-outline"></i>
                    </span>
                    <input v-model="register_info.inviteCode" placeholder="邀请码" class="password-input"
                        @keydown.enter="register_fc" />
                </div>
            </div>
            <div class="buttons">
                <button class="button" @click="register_fc">注 册</button>
                <router-link to="/login/" class="login">已有账号，前往登录</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'register',
    data() {
        return {
            input_length: 0,
            register_info: {
                code: null,
                email: null,
                inviteCode: null,
                password: null,
                transactionPassword: null,
                username: null,
            },
            email: null,
            loading: false,
            timer_yzm: '',
            timer_yzm_flag: true,
            s: null,
            yam_wenzi: '获取验证码',
            loading1 : false,
        }
    },
    methods: {
        preventInvalidChars(event) {
            // 允许字母和数字
            const invalidChars = /[^a-zA-Z0-9]/;  // 只允许字母和数字
            const isChinese = /[\u4e00-\u9fa5]/; // 中文字符范围

            // 如果按下的键是空格、特殊符号或中文字符，则阻止
            if (invalidChars.test(event.key) || isChinese.test(event.key)) {
                event.preventDefault();  // 阻止输入
            }
        },

        // 清理已输入的非法字符（空格、特殊符号和中文）
        removeInvalidChars() {
            // 只保留字母和数字
            this.register_info.username = this.register_info.username.replace(/[^\w\d]/g, '');
        },
        preventSpace(event) {
            // 如果按下的是空格键（keyCode 32 或 event.key === ' '），则阻止事件
            if (event.key === ' ' || event.keyCode === 32) {
                event.preventDefault();
            }
        },
        preventSpace1(event) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                return; // 不做任何操作，允许删除
            }
            // 如果按下的是空格键（keyCode 32 或 event.key === ' '），则阻止事件
            if (event.key === ' ' || event.keyCode === 32) {
                event.preventDefault();
            }

            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();  // 阻止非数字字符输入
            }
        },
        s1() {
            if (this.s > 0) {
                this.s--;
                this.yam_wenzi = this.s
                this.timer_yzm_flag = false
            } else {
                clearInterval(this.timer_yzm)
                this.yam_wenzi = '获取验证码'
                this.timer_yzm_flag = true
            }
        },
        getyzm() {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(this.email)) {
                this.$message("邮箱输入错误")
            } else if (this.timer_yzm_flag) {
                this.loading1 = true
                this.register_info.email = this.email
                this.s = 60;
                this.timer_yzm = setInterval(this.s1, 1000)
                axios.post(global.url + '/rsh-user/sendCode?email=' + this.register_info.email)
                    .then(response => {
                        this.loading1 = false
                        if (response.data.code == 200) {
                            this.$message(response.data.msg + '（！验证码请注意邮箱或者邮箱垃圾箱！）')
                        } else {
                            this.$message("邮箱验证码发送失败" + response.data.msg)
                        }
                    })
                    .catch(error => {
                        this.loading1 = false
                        this.$message("网络错误！请检查网络或联系客服"+error.msg);
                    });

            } else if (!this.timer_yzm_flag) {
                this.$message(this.yam_wenzi + "秒后尝试 ")
            }
        },
        countInputLength(){
            this.input_length = this.register_info.transactionPassword.length
        },
        register_fc() {
            this.loading = true
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zAZ]{2,6}$/
            if (!emailRegex.test(this.register_info.email)) {
                this.loading = false
                this.$message("邮箱输入有误")
            } else {
                if ((this.register_info.username != null) && (this.register_info.password != null) && (this.register_info.code != null) && (this.register_info.email != null) && (this.register_info.inviteCode != null) && (this.register_info.transactionPassword != null) && (this.input_length == 6)) {
                    if (this.register_info.password != this.register_info.input2) {
                        this.loading = false
                        this.$message("两次密码不一样")
                    } else {
                        axios.post(global.url + '/rsh-user/register', this.register_info)
                            .then(response => {
                                if (response.data.code == '200') {
                                    this.loading = false;
                                    Cookies.set('token', response.data.token, { expires: 1 });
                                    this.$message("用户注册成功")
                                    this.$router.push({ path: '/login' })

                                } else {
                                    this.loading = false;
                                    this.$message(response.data.msg);
                                }
                            })
                            .catch(error => {
                                this.loading = false;
                                this.$message("网络错误！请检查网络或联系客服"+error.msg);
                            });
                    }
                } else {
                    this.loading = false;
                    this.$message("信息未填写完整或者输入有误！")
                }
            }
        }
    },
    mounted() {
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 30vw;
    height: 43.12vw;
    background-image: url("");
}

.window {
    width: 30vw;
    height: 43.12vw;
    background-color: rgba(162, 159, 159, 0.099);
    backdrop-filter: blur(6px);
    border-radius: 1rem;
}

.title {
    width: 30vw;
    height: 6.5vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.da {
    color: white;
    font-size: 3vw;
    font-family: ziti3;
}

.info {
    width: 27vw;
    margin-top: -1.2vw;
    margin-left: 1.8vw;
    height: 30.18vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.password-container {
    position: relative;
    width: 30vw;
    max-width: 30vw;
    /* 可根据需要调整宽度 */
}

.password-input {
    background-color: rgb(91, 91, 91);
    width: 18vw;
    margin-left: 3vw;
    padding: 0.5vw 0.5vw 0.5vw 2vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: none;
    /* 边框设置为 vw 单位 */
    border-radius: 1vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.password-input:focus {
    outline: none;
}

.password-input::placeholder {
    font-size: 1vw;
}

.password-toggle_h {
    position: absolute;
    top: 47%;
    left: 3.3vw;
    transform: translateY(-50%);
    font-size: 1.5vw;
    /* 图标大小 */
    color: grey;
    transition: color 0.3s;
}

.buttons {
    width: 27vw;
    margin-left: 1.5vw;
    margin-top: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.button {
    width: 16vw;
    margin-left: 5.5vw;
    height: 2.5vw;
    font-size: 2vw;
    font-family: ziti3;
    border-radius: 2vw;
    border: none;
    background-color: #53aaa7;
    color: white;
}

.login {
    margin-top: 0.5vw;
    font-size: 1vw;
    font-family: ziti3;
    color: white;
}

.tzm_box {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.yzm-container {
    position: relative;
    width: 100%;
    max-width: 20vw;
    /* 可根据需要调整宽度 */
}

.yzm-input {
    background-color: rgb(91, 91, 91);
    width: 8vw;
    margin-left: 0.9vw;
    padding: 0.5vw 0.5vw 0.5vw 2vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: none;
    /* 边框设置为 vw 单位 */
    border-radius: 1vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.yzm-input:focus {
    outline: none;
}

.yzm-input::placeholder {
    font-size: 1vw;
}

.yzm-toggle_h {
    position: absolute;
    top: 47%;
    left: 1.2vw;
    transform: translateY(-50%);
    font-size: 1.5vw;
    /* 图标大小 */
    color: grey;
    transition: color 0.3s;
}


.yzm-toggle-button {
    position: absolute;
    width: 9vw;
    top: 50%;
    right: -1.3vw;
    transform: translateY(-50%);
    padding: 0.5vw 1vw;
    font-size: 1.2vw;
    font-family: ziti3;
    cursor: pointer;
    background-color: #53aaa7;
    color: white;
    border: none;
    border-radius: 1vw;
    transition: backgroung-color 0.3s;
}
</style>