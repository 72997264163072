<template>
    <div class="main">
        <div class="chuangkou1">
            <div class="xiugai">
                <router-link to="/myself/changekey">
                    <button class="bt_ch">修改登陆密码</button>
                </router-link>
                <router-link to="/myself/changejy">
                    <button class="bt_ch">修改交易密码</button>
                </router-link>
            </div>
        </div>
        <div class="chuangkou2" v-loading="loading">
            <div class="ck2_wz">
                <span class="da">提 现 地 址 绑 定</span>
            </div>
            <div class="dizhi">
                <img class="zfb_icon" :src="cdn+'gerenzhongxin/anquanzhongxin/zfb.png'" />
                <span class="zfb_wz">绑定支付宝</span>
                <div class="shuru">
                    <input v-if="bd_1" v-model="payInfo_data.alipayName" :placeholder="bd_1_info1" class="name"></input>
                    <input v-if="bd_1" v-model="payInfo_data.alipayAccount" :placeholder="bd_1_info2"
                        @input="removeInvalidChars" @keypress="preventInvalidChars" class="id"></input>
                    <input v-if="!bd_1" v-model="payInfo_data.alipayName" :placeholder="bd_1_info1" readonly="readonly"
                        class="name"></input>
                    <input v-if="!bd_1" v-model="payInfo_data.alipayAccount" :placeholder="bd_1_info2"
                        readonly="readonly" class="id"></input>
                </div>
                <button v-if="bd_1" class="zfb-bd" @click="payInfo">绑 定</button>
            </div>
            <div class="dizhi">
                <img class="zfb_icon" :src="cdn+'gerenzhongxin/anquanzhongxin/wx.png'" />
                <span class="zfb_wz">绑定微信</span>
                <div class="shuru">
                    <input v-if="bd_2" v-model="payInfo_data.wechatName" :placeholder="bd_2_info1" class="name"></input>
                    <input v-if="bd_2" v-model="payInfo_data.wechatAccount" :placeholder="bd_2_info2"
                        @input="removeInvalidChars1" @keydown="preventInvalidChars1" class="id"></input>
                    <input v-if="!bd_2" v-model="payInfo_data.wechatName" :placeholder="bd_2_info1" readonly="readonly"
                        class="name"></input>
                    <input v-if="!bd_2" v-model="payInfo_data.wechatAccount" :placeholder="bd_2_info2"
                        readonly="readonly" class="id"></input>
                </div>
                <button v-if="bd_2" class="zfb-bd" @click="payInfo1">绑 定</button>
            </div>
            <div class="dizhi">
                <img class="zfb_icon" :src="cdn+'gerenzhongxin/anquanzhongxin/USDT.png'" />
                <span class="zfb_wz">绑定USDT</span>
                <span class="zfb_wz_x">（请确认好usdt收款地址，如信息错误导致打款未到帐后果自负）</span>
                <div class="shuru">
                    <input v-if="bd_3" v-model="payInfo_data.usdtChainType" :placeholder="bd_3_info1"
                        class="name" readonly></input>
                    <input v-if="bd_3" v-model="payInfo_data.usdtWalletAddress" :placeholder="bd_3_info2"
                        @input="removeInvalidChars2" @keydown="preventInvalidChars2" class="id"></input>
                    <input v-if="!bd_3" v-model="payInfo_data.usdtChainType" :placeholder="bd_3_info1"
                        readonly="readonly" class="name"></input>
                    <input v-if="!bd_3" v-model="payInfo_data.usdtWalletAddress" :placeholder="bd_3_info2"
                        readonly="readonly" class="id"></input>
                </div>
                <button v-if="bd_3" class="zfb-bd" @click="payInfo2">绑 定</button>
            </div>
            <span class="xiao">*更改提现地址请联系客服</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
import { Message } from 'element-ui';

export default {
    name: 'AnquanZhongxin',
    data() {
        return {
            cdn:'https://rongshihui.cdn2.cc/files/assets/',
            payInfo_data: {
                alipayAccount: '',
                alipayName: '',
                usdtChainType: 'tron(TRC20)',
                usdtWalletAddress: '',
                wechatAccount: '',
                wechatName: ''
            },
            loading: false,
            bd_1: true,
            bd_2: true,
            bd_3: true,
            bd_1_info1: '',
            bd_1_info2: '',
            bd_2_info1: '',
            bd_2_info2: '',
            bd_3_info1: '',
            bd_3_info2: '',
            timer: '',
        }
    },
    methods: {
        preventInvalidChars(event) {
            const invalidChars = /[a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/;  // 只允许字母和数字
            const isChinese = /[\u4e00-\u9fa5]/; // 中文字符范围

            // 通过event.key捕获输入的字符，判断是否为非法字符
            if (!invalidChars.test(event.key) || isChinese.test(event.key)) {
                event.preventDefault();  // 阻止输入
            }
        },

        // 清理已输入的非法字符（空格、特殊符号和中文）
        removeInvalidChars() {
            // 只保留字母和数字
            this.payInfo_data.alipayAccount = this.payInfo_data.alipayAccount.replace(/[^a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/g, '');
        },
        preventInvalidChars1(event) {
            const invalidChars = /[a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/;  // 只允许字母和数字
            const isChinese = /[\u4e00-\u9fa5]/; // 中文字符范围

            // 通过event.key捕获输入的字符，判断是否为非法字符
            if (!invalidChars.test(event.key) || isChinese.test(event.key)) {
                event.preventDefault();  // 阻止输入
            }
        },

        // 清理已输入的非法字符（空格、特殊符号和中文）
        removeInvalidChars1() {
            // 只保留字母和数字
            this.payInfo_data.alipayAccount = this.payInfo_data.alipayAccount.replace(/[^a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/g, '');
        },
        preventInvalidChars2(event) {
            const invalidChars = /[a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/;  // 只允许字母和数字
            const isChinese = /[\u4e00-\u9fa5]/; // 中文字符范围

            // 通过event.key捕获输入的字符，判断是否为非法字符
            if (!invalidChars.test(event.key) || isChinese.test(event.key)) {
                event.preventDefault();  // 阻止输入
            }
        },

        // 清理已输入的非法字符（空格、特殊符号和中文）
        removeInvalidChars2() {
            // 只保留字母和数字
            this.payInfo_data.alipayAccount = this.payInfo_data.alipayAccount.replace(/[^a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|`~\-]/g, '');
        },
        f5(){
            this.$router.go(0)
        },
        payInfo() {
            this.loading = true
            axios.post(global.url + '/userPaymentInfo', this.payInfo_data, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                    if (response.data.code == '200') {
                        this.loading = false;
                        this.$message({
                            type: 'success',
                            message: '绑定信息成功~'
                        })
                        this.timer = setTimeout(this.f5,1000)
                    } else {
                        this.loading = false;
                        this.$message('错误!'+response.msg);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message('失败!');
                });
        },
        payInfo1() {
            this.loading = true
            axios.post(global.url + '/userPaymentInfo', this.payInfo_data, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                    if (response.data.code == '200') {
                        this.loading = false;
                        this.$message({
                            type: 'success',
                            message: '绑定信息成功~'
                        })
                        this.timer = setTimeout(this.f5,1000)
                        this.$router.go(0)
                    } else {
                        this.loading = false;
                        this.$message('错误!'+response.msg);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message('失败!');
                });
        },
        payInfo2() {
            this.loading = true
            axios.post(global.url + '/userPaymentInfo', this.payInfo_data, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                    if (response.data.code == '200') {
                        this.loading = false;
                        this.$message({
                            type: 'success',
                            message: '绑定信息成功~'
                        })
                        this.timer = setTimeout(this.f5,1000)
                        this.$router.go(0)
                    } else {
                        this.loading = false;
                        this.$message('错误!'+response.msg);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message('失败!');
                });
        },
        fectchData() {
            this.loading = true
            axios.get(global.url + '/userPaymentInfo', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            })
                .then(response => {
                    if (response.data.code == '200') {
                        this.loading = false;
                        if ((response.data.data.alipayName != null) && (response.data.data.alipayAccount != null)) {
                            this.bd_1 = false
                            this.bd_1_info1 = response.data.data.alipayName
                            this.bd_1_info2 = response.data.data.alipayAccount
                        } else {
                            this.bd_1 = true
                            this.bd_1_info1 = '支付宝真实姓名'
                            this.bd_1_info2 = '支付宝账号'
                        }
                        if ((response.data.data.wechatName != null) && (response.data.data.wechatAccount != null)) {
                            this.bd_2 = false
                            this.bd_2_info1 = response.data.data.wechatName
                            this.bd_2_info2 = response.data.data.wechatAccount
                        } else {
                            this.bd_2 = true
                            this.bd_2_info1 = '微信真实姓名'
                            this.bd_2_info2 = '微信账号'
                        }
                        if ((response.data.data.usdtWalletAddress != null) && (response.data.data != null)) {
                            this.bd_3 = false
                            this.payInfo_data.usdtChainType = ''
                            this.bd_3_info1 = response.data.data.usdtChainType
                            this.bd_3_info2 = response.data.data.usdtWalletAddress
                        } else {
                            this.bd_3 = true
                            this.bd_3_info1 = '目前仅支持tron(TRC20)'
                            this.bd_3_info2 = 'USDT收款链接'
                        }
                    } else if (response.data.code == '400') {
                        this.loading = false
                        this.bd_1 = true
                        this.bd_1_info1 = '支付宝真实姓名'
                        this.bd_1_info2 = '支付宝账号'
                        this.bd_2 = true
                        this.bd_2_info1 = '微信真实姓名'
                        this.bd_2_info2 = '微信账号'
                        this.bd_3 = true
                        this.bd_3_info1 = 'USDT类型'
                        this.bd_3_info2 = 'USDT收款链接'
                    } else {
                        this.$message('错误!' + response.msg);
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message('失败!');
                });
        }
    },
    mounted() {
        this.fectchData();
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
}

.chuangkou1 {
    width: 100%;
    height: 10%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.xiugai {
    width: 70%;
    height: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 15%;
}

.bt_ch {
    width: 12vw;
    height: 3vw;
    border: none;
    border-radius: 2vw;
    font-size: 1.5vw;
    font-family: ziti3;
    color: white;
    background-color: #53aaa7;
    text-align: center;
}

.bt_ch:hover {
    background-color: grey;
}

.chuangkou2 {
    margin-top: 1%;
    width: 100%;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
}

.ck2_wz {
    width: 96%;
    margin-left: 2%;
    height: 10%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.da {
    font-size: 2.8vw;
    font-family: ziti3;
    color: white;
}

.dizhi {
    margin-top: 1%;
    position: relative;
    width: 90%;
    height: 25%;
    margin-left: 5%;
    border: 0.2vw solid #53aaa7;
    border-radius: 1vw;
}

.zfb_icon {
    position: absolute;
    width: 7%;
    top: 5%;
    left: 1%;
}

.zfb_wz {
    position: absolute;
    top: 13%;
    left: 10%;
    font-size: 1.8vw;
    font-family: ziti3;
    color: white;
}

.zfb_wz_x {
    position: absolute;
    top: 18%;
    left: 25%;
    font-size: 1.2vw;
    font-family: ziti3;
    color: white;
}

.shuru {
    position: absolute;
    width: 96%;
    top: 45%;
    left: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.zfb-bd {
    position: absolute;
    width: 12%;
    height: 20%;
    bottom: 3%;
    right: 2%;
    border: none;
    border-radius: 2vw;
    font-size: 1.5vw;
    font-family: ziti3;
    text-align: center;
    color: white;
    background-color: rgb(220, 82, 82);
}

.zfb-bd:hover {
    background-color: rgb(38, 12, 12);
}

.xiao {
    width: 96%;
    height: 7%;
    margin-left: 5%;
    font-size: 1vw;
    font-family: ziti3;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.name {
    width: 10vw;
    height: 10%;
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: 0.2vw solid #ccc;
    /* 边框设置为 vw 单位 */
    border-radius: 0.5vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.name:focus {
    outline: none;
    border-color: #409eff;
    /* 聚焦时的边框颜色 */
}

.name::placeholder {
    font-size: 1vw;
}

.id {
    width: 22.5vw;
    height: 10%;
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: 0.2vw solid #ccc;
    /* 边框设置为 vw 单位 */
    border-radius: 0.5vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.id:focus {
    outline: none;
    border-color: #409eff;
    /* 聚焦时的边框颜色 */
}

.id::placeholder {
    font-size: 1vw;
}

.usdp_id {
    width: 74%;
    height: 10%;
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: 0.2vw solid #ccc;
    /* 边框设置为 vw 单位 */
    border-radius: 0.5vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.usdp_id:focus {
    outline: none;
    border-color: #409eff;
    /* 聚焦时的边框颜色 */
}

.usdp_id::placeholder {
    font-size: 1vw;
}
</style>