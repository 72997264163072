<template>
    <div class="main">
        <div class="chuangkou" v-loading="loading2">
            <div class="xinxi">
                <span class="da">佣 金 收 益</span>
            </div>
            <div class="jilu">
                <el-table :data="tableData" style="width: 100%;height: 100%;"
                    :header-cell-style="{ backgroundColor: '#393939', color: 'white',fontSize: '1.3vw' }"
                    :row-style="{ backgroundColor: 'transparent', color: 'white',fontSize: '1.2vw' }" height="100%">
                    <el-table-column prop="userName" label="下级用户名"  align="center">
                    </el-table-column>
                    <el-table-column prop="transactionId" label="任务单号" align="center">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="完成时间" width="200" align="center">
                    </el-table-column>
                    <el-table-column prop="amount" label="获得积分"  align="center">
                    </el-table-column>
                </el-table>
            </div>
            <div class="tiao">
                <pagination :total="shouyi.total" :currentPage="shouyi.page" :pageSize="shouyi.size"
                    @update:currentPage="handleCurrentChange" @update:pageSize="handleSizeChange" />


            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
import Pagination from './zujian/Pagination.vue';

export default {
    name: 'RenwuJilu',
    components: {
        Pagination
    },
    data() {
        return {
            tableData: [],
            shouyi: {
                total: 0,
                total_page: 0,
                page: 1,
                size: 10,
            },
            loading2: false,
        }
    }, methods: {
        handleCurrentChange(val) {
            this.loading2 = true
            this.shouyi.page = val;
            axios.get(global.url + '/agent/commissionList?pageNum=' + this.shouyi.page + '&pageSize=' + this.shouyi.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "查询成功") {
                    this.loading2 = false
                    this.tableData = response.data.rows
                    this.shouyi.total = response.data.total
                    this.shouyi.total_page = this.shouyi.total / this.shouyi.size
                } else {
                    this.loading2 = false
                    this.$message("佣金获取失败！"+response.data.msg);
                    this.$router.push({ path: '/login' });
                }
            }).catch(error => {
                this.loading2 = false
                this.$message("网络错误！请检查网络或联系客服"+error.msg);
            })
        },
        handleSizeChange(val) {
            this.loading2 = true
            this.shouyi.size = val;
            axios.get(global.url + '/agent/commissionList?pageNum=' + this.shouyi.page + '&pageSize=' + this.shouyi.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "查询成功") {
                    this.loading2 = false
                    this.tableData = response.data.rows
                    this.shouyi.total = response.data.total
                    this.shouyi.total_page = this.shouyi.total / this.shouyi.size
                } else {
                    this.loading2 = false
                    this.$message("佣金获取失败！"+response.data.msg);
                    this.$router.push({ path: '/login' });
                }
            }).catch(error => {
                this.loading2 = false
                this.$message("网络错误！请检查网络或联系客服"+error.msg);
            })
        },
        fetchData() {
            this.loading2 = true;
            axios.get(global.url + '/agent/commissionList?pageNum=' + this.shouyi.page + '&pageSize=' + this.shouyi.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "查询成功") {
                    this.loading2 = false
                    this.tableData = response.data.rows
                    this.shouyi.total = response.data.total
                    this.shouyi.total_page = this.shouyi.total / this.shouyi.size
                } else {
                    this.loading2 = false
                    this.$message("佣金获取失败！" + response.data.msg);
                    this.$router.push({ path: '/login' });
                }
            }).catch(error => {
                this.loading2 = false
                this.$message("网络错误！请检查网络或联系客服"+error.msg);
            })
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style lang="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.chuangkou {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1rem;
}

.da {
    position: absolute;
    font-size: 2.8vw;
    font-family: ziti3;
    color: white;
    top: 1%;
    left: 40%;
}

.jilu {
    position: absolute;
    top: 8%;
    left: 5%;
    width: 90%;
    height: 86%;
    font-family: ziti3;
}


.el-table {
    background-color: transparent;
}

::v-deep .el-table .el-table__row:hover {
    color: black !important;
}

.tiao {
    position: absolute;
    bottom: 1%;
    left: 15%;
    font-size: 80%;
    color: white;
    font-family: ziti3;
}
</style>