<template>
    <div class="pagination">
      <!-- 总条数 -->
      <span class="total-count">总条数：{{ total }}</span>
      <span class="label">每页显示</span>
      <select v-model="pageSize" @change="handlePageSizeChange" class="page-size-select">
        <option :value="10">10条</option>
        <option :value="20">20条</option>
        <option :value="30">30条</option>
      </select>
      <span class="label">跳转到</span>
      <input
        type="number"
        v-model="currentPageInput"
        :min="1"
        :max="totalPages"
        @input="handlePageInputChange"
        class="page-input"
      />
      <button @click="prevPage" :disabled="currentPage <= 1" class="pagination-button">上一页</button>
      <button @click="nextPage" :disabled="currentPage >= totalPages" class="pagination-button">下一页</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      total: {
        type: Number,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      },
      pageSize: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        currentPageInput: this.currentPage
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.total / this.pageSize);
      }
    },
    methods: {
      handlePageSizeChange() {
        this.$emit('update:currentPage', 1);
        this.$emit('update:pageSize', this.pageSize);
      },
      handlePageInputChange() {
        if (this.currentPageInput < 1) {
          this.currentPageInput = 1;
        } else if (this.currentPageInput > this.totalPages) {
          this.currentPageInput = this.totalPages;
        }
        this.$emit('update:currentPage', this.currentPageInput);
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.$emit('update:currentPage', this.currentPage - 1);
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.$emit('update:currentPage', this.currentPage + 1);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    margin-top: 2vw;
    display: flex;
    align-items: center;
    font-size: 1vw;
  }
  
  .total-count {
    font-size: 1.3vw;
    margin-right: 2vw;
  }
  
  .label {
    font-size: 1.3vw;
    margin-left: 0.5vw;
  }
  
  .page-size-select {
    font-size: 1vw;
    margin-left: 1vw;
    padding: 0.5vw;
    width: 5.5vw;
    height: 2.2vw;
    border-radius: 1vw;
    background-color: #53aaa7;
    color: white;
  }
  
  .page-input {
    font-size: 1vw;
    margin-left: 1vw;
    padding: 0.5vw;
    height: 1.1vw;
    width: 4vw;
    text-align: center;
    border-radius: 1vw;
    background-color: #53aaa7;
    color: white;
  }
  
  .pagination-button {
    font-size: 1vw;
    font-family: ziti3;
    padding: 0.5vw 1vw;
    margin-left: 2vw;
    border-radius: 2vw;
    background-color: #53aaa7;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  </style>