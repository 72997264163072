<template>
    <div class="main">
        <div class="window" v-loading="loading">
            <div class="input_box">
                <input placeholder="请输入激活码" v-model="info.cardKey" class="input-with-select">
                    <button class="jihuo_bt" on="el-icon-position" @click="button">立即激活</button>
                </input>
            </div>
            <div class="context">
                <span>使用卡密激活时，需要注意以下几点‌：</span>               
                <span>1.注意区分大小写‌：</span>
                <span>有些卡密是区分大小写的，需要按照卡密上的字母大小写来输入‌。</span>
                <span>2.确保卡密准确无误‌：</span>
                <span>输入卡密时要仔细核对，避免因输入错误导致激活失败‌或账号重复激活。</span>
                <span>3.激活码使用及获取：</span>
                <span>同一兑换码只可激活一次 重复激活无效，激活码在官网联系客服或联系上级获取。</span>
                <span>4.信息安全：</span>
                <span>激活码切勿泄露给他人 若被他人获取则后果自负，同一账号输入不同激活码仅增加时效。</span>
                <span>5.遇到困难：</span>
                <span>如果遇到问题，可以联系官网客服或上级寻求帮助和解决方案‌</span>
                <span>以上注意事项，可以确保顺利完成激活过程，并保护个人信息和使用安全，一切以官网信息为主</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'jihuo',
    data(){
        return{
            info:{
                cardKey: '',
            },
            loading: false,
        }
    },
    methods:{
        button(){
            this.loading = true
            axios.post(global.url + "/user/activate",this.info,{
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response=>{
                console.log("激活",response)
                this.loading = false
                this.$message(response.data.msg)
                if(response.data.msg == '激活码使用成功'){
                    this.$router.push({ path : '/myself'})
                }
            }).catch(error=>{
                this.loading= false
                this.$message("网络错误！请检查网络或联系客服"+error.msg);
            })
        },
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
}

.window {
    width: 100%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1vw;
}

.input_box {
    width: 80%;
    height: 30%;
    margin-left: 10%;
    position: relative;
}

.input-with-select{
    position: absolute;
    font-size: 1vw;
    border: none;
    top:20%;
    left: 10%;
    height: 30%;
    width: 80%;
}
.jihuo_bt{
    position: absolute;
    font-size: 1.5vw;
    font-family: ziti3;
    border: none;
    top:70%;
    left: 75.5%;
    height: 31.5%;
    width: 15%;
    background-color: rgb(82, 147, 232);
    color: white;
    border-radius: 1vw;
}

.jihuo_bt:hover{
    background-color: rgb(33, 55, 83);
}

.context {
    width: 90%;
    margin-left: 5%;
    height: 40%;
    font-size: 1.3vw;
    font-family: ziti3;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
</style>