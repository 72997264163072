<template>
    <div class="main">
        <div class="window" v-loading="loading">
            <div class="zz">
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-unlock"></i>
                    </span>
                    <input type='password' v-model="changes.oldPasswd" @keydown="preventSpace1"
                        @input="countInputLength" placeholder="请输入旧密码(6位纯数字)" class="password-input" />
                </div>
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-lock"></i>
                    </span>
                    <input type='password' v-model="changes.newPasswd" @keydown="preventSpace1"
                        @input="countInputLength1" placeholder="请输入新密码(6位纯数字)" class="password-input" />
                </div>
                <button class="button" @click="change">确定</button>
                <router-link class="back" to="/myself/AnquanZhongxin">返回</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'changejy',
    data() {
        return {
            input_length: 0,
            input_length_1: 0,
            changes: {
                oldPasswd: '',
                newPasswd: '',
            },
            loading: false
        }
    },
    methods: {
        preventSpace1(event) {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                return; // 不做任何操作，允许删除
            }
            // 如果按下的是空格键（keyCode 32 或 event.key === ' '），则阻止事件
            if (event.key === ' ' || event.keyCode === 32) {
                event.preventDefault();
            }

            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();  // 阻止非数字字符输入
            }
        },
        countInputLength(){
            this.input_length = this.changes.oldPasswd.length
        },
        countInputLength1(){
            this.input_length_1 = this.changes.newPasswd.length
        },
        change() {
            this.loading = true
            if ((this.input_length == 6) && (this.input_length_1 == 6)) {
                axios.post(global.url + '/userSecure/updateTransactionPasswd?oldPasswd=' + this.changes.oldPasswd + '&newPasswd=' + this.changes.newPasswd, '', {
                    headers: { "Authorization": "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.msg == '修改成功') {
                        this.loading = false;
                        this.$message({
                            message: '修改交易密码成功',
                            type: 'success'
                        })
                        this.$router.push({ path: '/myself/AnquanZhongxin' })

                    } else {
                        this.loading = false;
                        this.$message(response.data.msg);
                    }
                })
                    .catch(error => {
                        this.loading = false;
                        this.$message("网络错误！请检查网络或联系客服" + error.msg);
                    });
            }else{
                this.loading = false;
                this.$message("交易密码必须为6位纯数字！");
            }
        }
    }
}
</script>

<style long="scss" scoped>
.main {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-image: url("");
}

.window {
    position: absolute;
    left: 15vw;
    top: 11vw;
    width: 50%;
    height: 30%;
    background-color: rgba(115, 114, 114, 0.508);
    backdrop-filter: blur(6px);
    border-radius: 1rem;
}

.zz {
    width: 80%;
    margin-left: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.password-container {
    position: relative;
    width: 30vw;
    max-width: 15vw;
    /* 可根据需要调整宽度 */
}

.password-input {
    width: 22.5vw;
    padding: 0.5vw 0.5vw 0.5vw 2vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: 0.2vw solid #ccc;
    /* 边框设置为 vw 单位 */
    border-radius: 0.5vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.password-input:focus {
    outline: none;
    border-color: #409eff;
    /* 聚焦时的边框颜色 */
}

.password-toggle_h {
    position: absolute;
    margin-left: 3%;
    transform: translateY(15%);
    font-size: 1.5vw;
    /* 图标大小 */
    color: black;
    transition: color 0.3s;
}

.button {
    width: 100%;
    height: 15%;
    border: none;
    border-radius: 2vw;
    font-size: 1.5vw;
    background-color: #53aaa7;
    color: white;
}

.button:hover {
    background-color: grey;
}

.back {
    width: 100%;
    height: 10%;
    font-size: 1vw;
    color: white;
}
</style>