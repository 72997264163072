<template>
    <div class="main">
        <div class="chuangkou" v-loading="loading1">
            <div class="xinxi"  > 
                <span class="da">团队邀请码：{{ yaoqingma }}</span>
                <span class="da">团队总人数：{{ change.total }}</span>
            </div>
            <div class="liebiao"  >
                <el-table :data="tableData" :header-cell-style="{ backgroundColor: '#393939', color: 'white',fontSize:'1.15vw' }"
                    :row-style="{ backgroundColor: 'transparent', color: 'white' ,fontSize:'1.1vw'}" height="100%">
                    <el-table-column prop="userName" label="用户名" align="center">
                    </el-table-column>
                    <el-table-column prop="role" label="等级" align="center">
                    </el-table-column>
                    <el-table-column prop="inviteCode" label="邀请码" align="center">
                    </el-table-column>
                    <el-table-column prop="ipInfo" label="IP" align="center">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="注册时间" align="center">
                    </el-table-column>
                    <el-table-column prop="isActive" label="激活状态" align="center">
                    </el-table-column>
                    <el-table-column prop="startDate" label="激活时间" align="center">
                    </el-table-column>
                    <el-table-column prop="endDate" label="到期时间" align="center">
                    </el-table-column>
                    <el-table-column prop="totalPoints" label="用户余额" align="center">
                    </el-table-column>
                    <el-table-column prop="spentPoints" label="提现余额" align="center">
                    </el-table-column>
                    <el-table-column prop="spentPoints" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-dropdown trigger="click" @command="jinshen">
                                <el-button type="primary" size="mini" @click="panduan(scope.row.role)">修改</el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item v-if="huangjin"
                                        :command="beforejinshen('gold_vip', scope.row.userId, scope.row.userName)">黄金</el-dropdown-item>
                                    <el-dropdown-item v-if="bojin"
                                        :command="beforejinshen('silver_vip', scope.row.userId, scope.row.userName)">铂金</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="change">
                <pagination :total="change.total" :currentPage="change.page" :pageSize="change.size"
                    @update:currentPage="handleCurrentChange" @update:pageSize="handleSizeChange" />

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie';
import Pagination from './zujian/Pagination.vue';



export default {
    name: 'WodeTuandui',
    components: {
        Pagination
    },
    data() {
        return {
            yaoqingma: '未登录',
            tableData: [],
            change: {
                total: 0,
                total_page: 0,
                page: 1,
                size: 10,
            },
            loading1: false,
            level: '',
            huangjin: false,
            bojin: false,
            i: 0,
            timer: '',
        }
    },
    methods: {
        beforejinshen(index, id, name) {
            return {
                'index': index,
                'id': id,
                'name': name,
            }
        },
        panduan(role) {
            if (role == '黄金会员') {
                if (Cookies.get('role') == 'diamond_vip') {
                    this.huangjin = false
                    this.bojin = true
                } else if (Cookies.get('role') == 'silver_vip') {
                    this.huangjin = false
                    this.bojin = false
                }
            } else if (role == '铂金会员') {
                if (Cookies.get('role') == 'diamond_vip') {
                    this.huangjin = false
                    this.bojin = false
                }
            } else if (role == 'VIP' || role == '普通会员') {
                if (Cookies.get('role') == 'diamond_vip') {
                    this.huangjin = true
                    this.bojin = true
                } else if (Cookies.get('role') == 'silver_vip') {
                    this.huangjin = true
                    this.bojin = false
                } else if (Cookies.get('role' == 'gold_vip')) {
                    this.huangjin = false
                    this.bojin = false
                }
            }
        },
        f5(){
            this.$router.go(0)
        },
        jinshen(val) {
            if (val.index == "gold_vip") {
                this.level = '黄金会员'
            } else if (val.index == "silver_vip") {
                this.level = '铂金会员'
            }
            this.$confirm('确定晋升 ' + val.name + ' 为 ' + this.level + '? 用户晋升后将不能降级！（降级请联系客服）(请代理告知用户重新登陆)', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(global.url + '/agent/upgradeLevel?userId=' + val.id + '&level=' + val.index, '', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.msg == "升级成功,请让用户重新登录") {
                        this.$message({
                            type: 'success',
                            message: '升级成功,请让用户重新登录"'
                        });
                        this.timer = setTimeout(this.f5,1000)
                    } else {
                        this.$message("晋升失败！" + response.data.msg);
                    }
                }).catch(error => {
                    this.$message("错误！检查网络或联系客服" + error);
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });
            })
        },
        handleCurrentChange(val) {
            this.$message("加载时间稍长，请稍后")
            this.loading1 = true
            this.change.page = val
            axios.get(global.url + '/agent/teamList?pageNum=' + this.change.page + '&pageSize=' + this.change.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {
                    this.tableData = response.data.rows;
                    this.change.total = response.data.total;
                    this.change.total_page = this.change.total / this.change.size;
                    for (this.i = 0; this.i < this.change.size; this.i++) {
                        if (this.tableData[this.i].role == 'gold_vip') {
                            this.tableData[this.i].role = '黄金会员'
                        } else if (this.tableData[this.i].role == 'silver_vip') {
                            this.tableData[this.i].role = '铂金会员'
                        } else if (this.tableData[this.i].role == 'vip') {
                            if (this.tableData[this.i].isActive == '0') {
                                this.tableData[this.i].role = '普通会员'
                            } else if (this.tableData[this.i].isActive == '1') {
                                this.tableData[this.i].role = 'VIP'
                            }
                        }
                        if (this.tableData[this.i].isActive == '0') {
                            this.tableData[this.i].isActive = '未激活'
                        } else if (this.tableData[this.i].isActive == '1') {
                            this.tableData[this.i].isActive = '已激活'
                        }
                    }
                    this.loading1 = false
                    this.$message("加载完成~")
                } else {
                    this.loading1 = false;
                    this.$message("团队列表获取失败！"+response.data.msg);
                }
            }).catch(error => {
                this.loading1 = false;
            })
        },
        handleSizeChange(val) {
            this.$message("加载时间稍长，请稍后")
            this.loading1 = true
            this.change.size = val
            axios.get(global.url + '/agent/teamList?pageNum=' + this.change.page + '&pageSize=' + this.change.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {
                    this.tableData = response.data.rows;
                    this.change.total = response.data.total;
                    this.change.total_page = this.change.total / this.change.size;
                    for (this.i = 0; this.i < this.change.size; this.i++) {
                        if (this.tableData[this.i].role == 'gold_vip') {
                            this.tableData[this.i].role = '黄金会员'
                        } else if (this.tableData[this.i].role == 'silver_vip') {
                            this.tableData[this.i].role = '铂金会员'
                        } else if (this.tableData[this.i].role == 'vip') {
                            if (this.tableData[this.i].isActive == '0') {
                                this.tableData[this.i].role = '普通会员'
                            } else if (this.tableData[this.i].isActive == '1') {
                                this.tableData[this.i].role = 'VIP'
                            }
                        }
                        if (this.tableData[this.i].isActive == '0') {
                            this.tableData[this.i].isActive = '未激活'
                        } else if (this.tableData[this.i].isActive == '1') {
                            this.tableData[this.i].isActive = '已激活'
                        }
                    }
                    this.loading1 = false
                    this.$message("加载完成~")
                } else {
                    this.loading1 = false;
                    this.$message("团队列表获取失败！"+response.data.msg);
                }
            }).catch(error => {
                this.loading1 = false;
            })
        },
        fectchData() {
            this.$message("加载时间稍长，请稍后")
            this.loading1 = true
            this.yaoqingma = Cookies.get("inviteId")
            axios.get(global.url + '/agent/teamList?pageNum=' + this.change.page + '&pageSize=' + this.change.size, {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "查询成功") {
                    this.change.total = response.data.total;
                    this.change.total_page = this.change.total / this.change.size;
                    this.tableData = response.data.rows;
                    for (this.i = 0; this.i < this.change.size; this.i++) {
                        if (this.tableData[this.i].role == 'gold_vip') {
                            this.tableData[this.i].role = '黄金会员'
                        } else if (this.tableData[this.i].role == 'silver_vip') {
                            this.tableData[this.i].role = '铂金会员'
                        } else if (this.tableData[this.i].role == 'vip') {
                            if (this.tableData[this.i].isActive == '0') {
                                this.tableData[this.i].role = '普通会员'
                            } else if (this.tableData[this.i].isActive == '1') {
                                this.tableData[this.i].role = 'VIP'
                            }
                        }
                        if (this.tableData[this.i].isActive == '0') {
                            this.tableData[this.i].isActive = '未激活'
                        } else if (this.tableData[this.i].isActive == '1') {
                            this.tableData[this.i].isActive = '已激活'
                        }
                    }
                    this.loading1 = false
                    this.$message("加载完成~")
                } else {
                    this.loading1 = false;
                    this.$message("团队列表获取失败！" + response.data.msg);
                }
            }).catch(error => {
                this.loading1 = false;
            })
        }
    },
    mounted() {
        this.fectchData();
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.chuangkou {
    width: 110%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1rem;
}

.xinxi {
    width: 100%;
    height: 5%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
}

.da {
    width: 50%;
    height: 100%;
    font-size: 1.5vw;
    font-family: ziti3;
    margin-top: 1%;
}

.liebiao {
    height: 90%;
    width: 98%;
    margin-left: 1%;
    font-family: ziti3;
}

.el-table {
    background-color: transparent;
}

::v-deep .el-table .el-table__row:hover {
    color: black !important;
}

.change {
    height: 10%;
    width: 90%;
    margin-top: -3%;
    margin-left: 15%;
    color: white;
}
</style>