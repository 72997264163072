<template>
    <div class="main">
        <div class="window" v-loading="loading">
            <div class="title">
                <span class="da">用户登录</span>
            </div>
            <div class="info">
                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-user"></i>
                    </span>
                    <input v-model="login_info.username" @input="removeInvalidChars" @keydown="preventInvalidChars"
                        placeholder="用户名" class="password-input" />
                </div>

                <div class="password-container">
                    <span class="password-toggle_h">
                        <i class="el-icon-unlock"></i>
                    </span>
                    <input type='password' @keydown="preventSpace" v-model="login_info.password" placeholder="请输入密码"
                        class="password-input" />
                </div>

                <div class="tzm_box">
                    <div class="yzm-container">
                        <span class="yzm-toggle_h">
                            <i class="el-icon-paperclip"></i>
                        </span>
                        <input v-model="login_info.code" placeholder="验证码" class="yzm-input"
                            @keydown.enter="login_fc" />
                        <button @click="getyzm" class="yzm-toggle-button">换一换</button>
                    </div>

                    <div class="picture" v-loading="loading1">
                        <img class="pic_st" :src="pic_src" />
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button class="button" @click="login_fc">登 录</button>
                <router-link to="/login/register" class="login">无账号，前往注册</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'gologin',
    data() {
        return {
            pic_src: null,
            login_info: {
                username: null,
                password: null,
                code: null,
                uuid: null,
            },
            loading: false,
            loading1: false,
        }
    },
    methods: {
        preventInvalidChars(event) {
            const invalidChars = /[^a-zA-Z0-9]/;  // 只允许字母和数字
            const isChinese = /[\u4e00-\u9fa5]/; // 中文字符范围

            // 如果按下的键是空格、特殊符号或中文字符，则阻止
            if (invalidChars.test(event.key) || isChinese.test(event.key)) {
                event.preventDefault();  // 阻止输入
            }
        },
        // 移除已输入的非法字符
        removeInvalidChars() {
            // 正则表达式移除所有非字母、数字字符（不包括中文、空格、特殊符号）
            this.login_info.username = this.login_info.username.replace(/[^\w\d]/g, '');
        },
        preventSpace(event) {
            // 如果按下的是空格键（keyCode 32 或 event.key === ' '），则阻止事件
            if (event.key === ' ' || event.keyCode === 32) {
                event.preventDefault();
            }
        },
        getyzm() {
            this.loading1 = true;
            axios.get(global.url + '/captchaImage')
                .then(response => {
                    this.loading1 = false;
                    this.pic_src = 'data:image/jpeg;base64,' + response.data.img;
                    this.login_info.uuid = response.data.uuid;
                })
                .catch(error => {
                    this.loading1 = false;
                    this.$message('验证码获取失败!' + error.msg);
                });
        },
        login_fc() {
            if ((this.login_info.username != null) && (this.login_info.password != null) && (this.login_info.code != null)) {
                if (this.login_info.uuid == null) {
                    this.$message("验证码错误！");
                } else {
                    this.loading = true;
                    axios.post(global.url + '/login', this.login_info)
                        .then(response => {
                            if (response.data.code == '200') {
                                this.loading = false;
                                Cookies.set('token', response.data.token, { expires: 1 });
                                this.$message({
                                    message: '用户登陆成功',
                                    type: 'info',
                                    duration: 500,
                                })
                                this.$router.push({ path: '/myself' })

                            } else {
                                this.getyzm();
                                this.loading = false;
                                this.$message(response.data.msg);
                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$message("网络错误！请检查网络或联系客服" + error.msg);
                        });
                }
            } else {
                this.$message("信息未填写完整！")
            }
        }
    },
    mounted() {
        this.getyzm();
    },
}
</script>

<style long="scss" scoped>
.main {
    width: 30vw;
    height: 43.12vw;
    background-image: url("");
}

.window {
    width: 30vw;
    height: 21.56vw;
    margin-top: 9vw;
    background-color: rgba(162, 159, 159, 0.099);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
}
.title {
    width: 30vw;
    height: 4.31vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.da {
    color: white;
    font-size: 2.5vw;
    font-family: ziti3;
}

.info {
    margin-top: -1vw;
    width: 27vw;
    height: 13vw;
    margin-left: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.buttons {
    width: 26.7vw;
    margin-left: 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.login {
    margin-top: 0.5vw;
    font-size: 1vw;
    font-family: ziti3;
    color: white;
}

.tzm_box {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.yzm-container {
    position: relative;
    width: 100%;
    max-width: 20vw;
    /* 可根据需要调整宽度 */
}

.yzm-input {
    background-color: rgb(91, 91, 91);
    width: 5vw;
    margin-left: 0.9vw;
    padding: 0.5vw 0.5vw 0.5vw 2vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: none;
    /* 边框设置为 vw 单位 */
    border-radius: 1vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.yzm-input:focus {
    outline: none;
}

.yzm-input::placeholder {
    font-size: 1vw;
}

.yzm-toggle_h {
    position: absolute;
    top: 47%;
    left: 1.2vw;
    transform: translateY(-50%);
    font-size: 1.5vw;
    /* 图标大小 */
    color: grey;
    transition: color 0.3s;
}

.yzm-toggle-button {
    position: absolute;
    top: 50%;
    right: -1.3vw;
    transform: translateY(-50%);
    padding: 0.5vw 1vw;
    font-size: 1.2vw;
    font-family: ziti3;
    cursor: pointer;
    background-color: #53aaa7;
    color: white;
    border: none;
    border-radius: 1vw;
    transition: backgroung-color 0.3s;
}

.picture {
    position: absolute;
    left: 12.4vw;
    height: 2.8vw;
    width: 6.5vw;
    background-color: white;
}

.pic_st {
    width: 6.5vw;
    height: 2.8vw;
}

.password-container {
    position: relative;
    width: 30vw;
    max-width: 30vw;
    /* 可根据需要调整宽度 */
}

.password-input {
    background-color: rgb(91, 91, 91);
    width: 18vw;
    margin-left: 3vw;
    padding: 0.5vw 0.5vw 0.5vw 2vw;
    /* 内边距设置为 vw 单位 */
    font-size: 1.2vw;
    /* 字体大小使用 vw 单位 */
    border: none;
    /* 边框设置为 vw 单位 */
    border-radius: 1vw;
    /* 圆角 */
    transition: border-color 0.3s;
}

.password-input:focus {
    outline: none;
}

.password-input::placeholder {
    font-size: 1vw;
}

.password-toggle_h {
    position: absolute;
    top: 47%;
    left: 3.3vw;
    transform: translateY(-50%);
    font-size: 1.5vw;
    /* 图标大小 */
    color: grey;
    transition: color 0.3s;
}

.button {
    width: 16vw;
    margin-left: 5.5vw;
    height: 2.5vw;
    font-size: 2vw;
    font-family: ziti3;
    border-radius: 2vw;
    border: none;
    background-color: #53aaa7;
    color: white;
}
</style>