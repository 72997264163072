<template>
    <div class="main">
        <div class="window">
            <router-view/>
        </div>
    </div>
</template>

<script>

export default{
    name: 'login'
}
</script>

<style long="scss" scoped>
.main{
    width: 100vw;
    height: 53.9vw;
    position: relative;
}
.window{
    position: absolute;
    left: 9vw;
    top: 6vw;
    width: 30vw;
    height: 43.12vw;
}
</style>